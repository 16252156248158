export const de_translations = 
{
    "general": {
        "friends": "Freunde",
        "conflict_log": "Konfliktprotokoll",
        "groceries_menu": "Einkaufsmenü",
        "lists": "Listen",
        "create_new_list": "Neue Liste erstellen",
        "manage_all_listgroups": "Alle Listengruppen verwalten",
        "other_actions": "Aktionen",
        "manage_categories": "Kategorien verwalten",
        "manage_all_items": "Alle Elemente verwalten",
        "view_global_items": "Globale Elemente anzeigen",
        "settings": "Einstellungen",
        "edit": "Bearbeiten",
        "editing": "In Bearbeitung",
        "active": "Aktiv",
        "inactive": "Inaktiv",
        "completed": "Abgeschlossen",
        "stocked_here": "Hier auf Lager",
        "category": "Kategorie",
        "uncategorized": "Ohne Kategorie",
        "quantity": "Menge",
        "uom_abbrev": "ME", // Abkürzung für Maßeinheit
        "no_uom": "Keine Maßeinheit", // Keine angegebene Maßeinheit
        "times": "Mal",
        "reset": "Zurücksetzen",
        "note": "Notiz",
        "cancel": "Abbrechen",
        "save": "Speichern",
        "add": "Hinzufügen",
        "delete": "Löschen",
        "name": "Name",
        "color": "Farbe",
        "needs_confirmed": "Muss bestätigt werden",
        "requested": "Angefordert",
        "confirmed": "Bestätigt",
        "needs_registering": "Muss registriert werden",
        "take_picture_for_item": "Nehmen Sie ein Bild von diesem Artikel auf",
        "loading_all_items": "Alle Artikel laden...",
        "all_items": "Alle Artikel",
        "loading_categories": "Kategorien laden...",
        "categories": "Kategorien",
        "loading_category": "Kategorie laden...",
        "items_using_category_one": "Es gibt {{count}} Artikel, der diese Kategorie verwendet.",
        "items_using_category_other": "Es gibt {{count}} Artikel, die diese Kategorie verwenden.",
        "lists_using_category_one": "Es gibt {{count}} Liste, die diese Kategorie verwendet.",
        "lists_using_category_other": "Es gibt {{count}} Listen, die diese Kategorie verwenden.",
        "delete_this_list": "Diese Liste löschen : {{list}} ?",
        "really_delete_list": "Möchten Sie diese Liste wirklich löschen?",
        "really_delete_list_extended": "Möchten Sie diese Liste wirklich löschen? Alle Informationen von dieser Liste gehen verloren.",
        "all_list_info_lost": "Alle Informationen auf dieser Liste gehen verloren.",
        "editing_category": "Kategorie bearbeiten: ",
        "loading_conflict_info": "Konfliktinformationen laden...",
        "conflict_item": "Konfliktposition",
        "from": "von",
        "main_differences": "Hauptunterschiede",
        "winner": "Gewinner",
        "losers": "Verlierer",
        "return": "Zurück",
        "loading_conflict_log": "Konfliktprotokoll laden...",
        "set_as_viewed": "Als angesehen markieren",
        "loading_friends": "Freunde laden...",
        "email_sent": "Eine Bestägigungs-E-Mail wurde an {{email}} gesendet. Die URL zur Bestätigung ist: {{url}}. Sie wurde auch in die Zwischenablage kopiert.",
        "url": "URL",
        "confirm": "Bestätigen",
        "prompt_register_friend": "Es ist kein Benutzer mit der E-Mail-Adresse {{email}} registriert. Möchtest du diese Person bitten, sich zu registrieren?",
        "adding_friend": "Einen neuen Freund hinzufügen",
        "email_address_friend": "E-Mail-Adresse des Freundes zum Hinzufügen",
        "user_not_found_send_registration": "Benutzer nicht gefunden, Registrierungsanfrage senden?",
        "send_registration": "Registrierung senden",
        "url_registration_confirmation": "URL zur Registrierungsbestätigung",
        "ok": "OK",
        "loading_global_item":"Globales Element wird geladen...",
        "undefined": "Nicht definiert",
        "viewing_global_item": "Globales Element anzeigen",
        "new_placeholder": "<NEU>",
        "default_category": "Standardkategorie",
        "default_uom": "Standard-Maßeinheit",
        "go_back": "Zurück",
        "loading_global_items": "Globale Elemente werden geladen...",
        "global_items": "Globale Elemente",
        "loading": "Wird geladen...",
        "logging_in": "Anmeldung...",
        "loading_item": "Element wird geladen...",
        "add_new_category": "Neue Kategorie hinzufügen",
        "add_mew_uom":"Neue Maßeinheit hinzufügen",
        "plural_description": "Mehrzahl Beschreibung",
        "delete_this_item": "Dieses Element löschen?",
        "really_delete_this_item":"Möchten Sie dieses Element wirklich löschen?",
        "editing_item":"Element bearbeiten:",
        "take_photo": "Foto aufnehmen",
        "delete_photo": "Foto löschen",
        "change_here_change_all_below":"Werte hier ändern, um sie auf allen Listen unten zu ändern",
        "item_note": "Element Notiz",
        "list_group": "Listen-Gruppe",
        "loading_items": "Elemente werden geladen",
        "continue_ignore": "Fortfahren/ Ignorieren",
        "items_on": "Elemente auf:",
        "search": "Suche",
        "delete_completed_items": "ABGESCHLOSSENE ELEMENTE LÖSCHEN",
        "loading_list": "Liste wird geladen...",
        "changing_selected_list":"Ausgewählte Liste wird geändert",
        "list_updated_not_saved_still_change":"Die Liste wurde aktualisiert und nicht gespeichert. Möchten Sie die Listen trotzdem ändern?",
        "editing_list":"Liste bearbeiten",
        "creating_new_list":"Neue Liste erstellen",
        "loading_list_group":"Listengruppe wird geladen...",
        "lists_assigned_to_group":"Listen, die dieser Gruppe zugewiesen sind:",
        "you_are_listgroup_owner": "Sie sind der Besitzer der Listengruppe",
        "is_listgroup_owner":"ist der Besitzer der Listengruppe",
        "listgroup_shared_with_users":"Die Listengruppe wird mit diesen anderen Benutzern geteilt:",
        "delete_this_listgroup":"Diese Listengruppe löschen: {{listgroup}} ?",
        "delete_this_listgroup_detail":"Möchten Sie diese Listengruppe wirklich löschen? Alle Informationen zu dieser Listengruppe gehen verloren (Listen und Elemente).",
        "changing_selected_listgroup":"Ausgewählte Listengruppe wird geändert",
        "changing_selected_listgroup_detail":"Die Listengruppe wurde aktualisiert und nicht gespeichert. Möchten Sie trotzdem die Listengruppen ändern?",
        "editing_listgroup":"Listengruppe bearbeiten:",
        "creating_listgroup":"Neue Listengruppe erstellen",
        "loading_listgroups": "Listengruppen werden geladen...",
        "listgroups": "Listengruppen",
        "all_lists": "Alle Listen",
        "delete_exit":"Löschen/Verlassen",
        "cancel_exit":"Abbrechen/Verlassen",
        "request_password_reset":"Kennwort zurücksetzen anfordern",
        "press_reset_receive_email":"Drücken Sie auf Zurücksetzen, um einen Link per E-Mail zum Zurücksetzen Ihres Passworts zu erhalten",
        "api_server_url":"API-Server-URL",
        "username": "Benutzername",
        "password": "Passwort",
        "email_address":"E-Mail-Adresse",
        "email": "E-Mail",
        "fullname": "Vollständiger Name",
        "confirm_password": "Passwort bestätigen",
        "login": "Anmelden",
        "reset_password": "Passwort zurücksetzen",
        "create_account": "Benutzerkonto erstellen",
        "create":"Erstellen",
        "work_offline":"Offline arbeiten",
        "login_page": "Anmeldeseite",
        "remove_saved_credentials":"Möchten Sie Ihre gespeicherten Anmeldeinformationen entfernen? Dies führt dazu, dass die Anwendung neu gestartet wird und Sie sich bei Bedarf erneut anmelden können.",
        "want_remove_local_database":"Möchten Sie die lokale Datenbank entfernen? Dies führt dazu, dass die Anwendung neu gestartet wird und Sie sich bei Bedarf erneut anmelden können. Es werden auch alle Daten vom Server erneut synchronisiert.",
        "app_info": "App-Info",
        "app_version": "App-Version",
        "database_schema_version": "Datenbankschema-Version",
        "user_info":"Benutzer-Info",
        "user_id": "Benutzer-ID",
        "logout":"Abmelden",
        "delete_local_data":"Lokale Daten löschen",
        "add_other_list_options":"Zu anderen Listoptionen hinzufügen",
        "add_same_group_auto":"Automatisch zur gleichen Gruppe hinzufügen",
        "add_same_categories_auto":"Automatisch Elemente mit denselben Kategorien hinzufügen",
        "dont_add_auto":"Nicht automatisch hinzufügen",
        "other_settings":"Weitere Einstellungen",
        "remove_items_all_lists_purchased":"Elemente aus allen Listen in der Listengruppe entfernen, wenn sie als gekauft/abgeschlossen markiert sind",
        "delete_all_lists_when_deleting_completed":"Aus allen Listen in der Listengruppe löschen, wenn abgeschlossene Elemente gelöscht werden",
        "days_conflict_log_to_view":"Tage des Konfliktprotokolls, die angezeigt werden sollen:",
        "language":"Sprache",
        "remove": "Entfernen",
        "loading_recipes": "Lade Rezepte...",
        "recipes": "Rezepte",
        "loading_recipe": "Lade Rezept...",
        "delete_this_recipe": "Rezept löschen",
        "really_delete_recipe": "Sind Sie sicher, dass Sie dieses Rezept löschen möchten?",
        "editing_recipe": "Rezept bearbeiten:",
        "items_in_recipe": "Zutaten im Rezept",
        "add_question": "Hinzufügen?",
        "item": "Zutat",
        "recipe_steps": "Rezept Schritte",
        "add_items_to": "Zutaten hinzufügen zu",
        "loading_recipe_import": "Lade Seite zum Importieren von Rezepten...",
        "import_new": "Neu importieren",
        "importing_recipe": "Importiere Rezept",
        "import_file": "Importiere Datei",
        "recipe_import_type": "Art der Rezept-Importdatei",
        "recipe_import_type_tandoor": "Tandoor",
        "recipe_import_type_json_ld": "JSON-LD",
        "loading_uoms": "Lade Maßeinheiten...",
        "uoms":"Maßeinheiten",
        "loading_uom": "Lade Maßeinheit...",
        "items_using_uom_one": "Es gibt {{count}} Zutat, die diese Maßeinheit verwendet.",
        "items_using_uom_other": "Es gibt {{count}} Zutaten, die diese Maßeinheit verwenden.",
        "recipes_using_uom_one": "Es gibt {{count}} Rezept, das diese Maßeinheit verwendet.",
        "recipes_using_uom_other": "Es gibt {{count}} Rezepte, die diese Maßeinheit verwenden.",
        "delete_this_uom": "Diese Maßeinheit löschen: {{uom}} ?",
        "really_delete_uom": "Diese Maßeinheit wird von allen Zutaten und Rezepten entfernt. Möchten Sie wirklich löschen?",
        "all_uom_info_lost": "Alle Maßeinheit-Informationen gehen verloren.",
        "editing_uom": "Maßeinheit bearbeiten",
        "alternate_abbreviations": "Alternativen Abkürzungen",
        "uom_code": "Code (ISO)",
        "add_recipe_to_list": "Rezept zur Liste hinzufügen",
        "add_items_in_recipe": "Fahren Sie fort und fügen Sie die Zutaten des Rezepts {{recipeName}} zur Liste hinzu.",
        "adding_recipe_to_list": "Füge Rezept zur Liste hinzu...",
        "status_adding_recipe":"Status des Hinzufügens von Rezept zur Liste:",
        "updated_item_successfully": "{{name}} wurde erfolgreich aktualisiert.",
        "added_item_successfully": "{{name}} wurde erfolgreich hinzugefügt.",
        "importing_recipes": "Importiere Rezepte...",
        "import_recipe_results": "Ergebnisse des Rezeptimports",
        "processing_zip_file": "Bearbeite Zip-Datei",
        "import_recipes_q": "Rezepte importieren?",
        "select_recipes_import": "Wählen Sie unten die zu importierenden Rezepte aus.",
        "loaded_recipe_successfully":"Rezept {{name}} wurde erfolgreich geladen.",
        "plural_name": "Pluralname",
        "description": "Beschreibung",
        "recipe_items_added_to_list":"Rezeptartikel zur Liste hinzugefügt",
        "item_on_recipe":"Artikel im Rezept:",
        "recipe_quantity":"Rezeptmenge",
        "recipe_uom": "Rezeptmaßeinheit",
        "shopping_quantity":"Einkaufsmenge",
        "shopping_uom": "Einkaufsmaßeinheit",
        "update_user_info": "Benutzerinformationen aktualisieren",
        "include_globalitems_in_search": "Globale Elemente in die Suche einbeziehen",
        "offline_cant_get_sharing_info": "Arbeiten offline, kann geteilte Benutzer nicht abrufen oder bearbeiten",
        "logged_in": "Angemeldet",
        "working_offline": "Offline arbeiten",
        "online": "Online",
        "attempt_login_again": "Erneut anmelden versuchen",
        "must_stay_offline": "Arbeiten Sie offline, und die API/Datenbank sind immer noch offline. Arbeiten Sie weiterhin offline, und Änderungen werden in der Warteschlange gehalten.",
        "as_user": "als Benutzer",
        "api_server_status": "API-Serverin:",
        "db_server_status": "Datenbankserver:",
        "refresh_token_valid": "Aktualisierungstoken:",
        "access_token_valid": "Zugriffstoken:",
        "expires_in": "Läuft ab in:",
        "expired_by": "Abgelaufen am:",
        "days_one": " Tag",
        "days_other": " Tage",
        "relogin": "Erneut anmelden",
        "confirm_remove_completed_items": "Bestätigen Sie, dass Sie abgeschlossene Elemente aus der Liste entfernen möchten",
        "delete_this_category": "Diese Kategorie löschen: {{category}}?",
        "really_delete_category": "Möchten Sie diese Kategorie wirklich löschen?",
        "all_category_info_lost": "Alle Informationen zu dieser Kategorie gehen verloren.",
        "deleting_category": "Kategorie wird gelöscht...",
        "deleting_list": "Liste wird gelöscht...",
        "deleting_uom": "Maßeinheit wird gelöscht...",
        "is_recipe_listgroup_for_user":"Ist die Rezeptliste für diesen Benutzer gruppiert?",
        "alexa_default": "Standard-Listengruppe für Alexa",
        "listgroup_not_updated": "Die Listengruppe wurde nicht aktualisiert/gespeichert",
        "exit": "App beenden"
    },
    "error": {
        "invalid_dbuuid" : "Ungültiger Datenbank-Identifier",
        "could_not_contact_api_server": "API-Server konnte nicht erreicht werden",
        "invalid_jwt_token": "JWT-Token ungültig",
        "db_server_not_available": "Datenbankserver nicht erreichbar",
        "no_existing_credentials_found": "Keine Zugangsdaten gefunden",
        "no_api_server_url_entered":"Keine API-Server-URL eingetragen",
        "no_couchdb_url_found":"Keine CouchDB-URL eingetragen",
        "invalid_api_url": "API-Server-URL ist ungültig",
        "invalid_couchdb_url": "CouchDB-URL ist ungültig",
        "no_database_name_found":"Kein Datenbankname gefunden",
        "no_database_username_entered": "Kein Benutzername für Datenbank eingetragen",
        "username_6_chars_or_more": "Der Benutzername für die Datenbank muss mindestens 6 Zeichen lang sein",
        "invalid_username_format":"Format des Benutzernamens ungültig",
        "invalid_fullname_format":"Format des Namens ungültig",
        "no_email_entered":"Keine E-Mail-Adresse angegeben",
        "invalid_email_format": "Format der E-Mail-Adresse ist ungültig",
        "no_password_entered":"Kein Passwort angegeben",
        "password_not_long_enough":"Das Passwort muss mindestens 6 Zeichen haben",
        "passwords_no_match":"Die Passwörter stimmen nicht überein",
        "could_not_find_items": "Elemente konnten nicht gefunden werden",
        "loading_item_info_restart": "Fehler beim Laden der Elemente. Neustart.",
        "no_items_available": "Keine Elemente verfügbar",
        "loading_category_info": "Fehler beim Laden der Kategorien. Neustart.",
        "must_enter_a_name": "Du musst einen Namen angeben",
        "duplicate_category_name": "Es existiert bereits eine Kategorie mit diesem Namen",
        "updating_category": "Fehler beim aktualisieren der Kategorie",
        "please_retry": "Bitte versuche es erneut",
        "unable_remove_category_items":"Fehler beim Löschen der Kategorie von Elementen",
        "unable_remove_category_lists":"Fehler beim Löschen der Liste von Elementen",
        "unable_delete_category": "Fehler beim Löschen der Kategorie",
        "loading_conflict_info": "Fehler beim Laden von Konfliktinformationen... Neustart.",
        "loading_conflict_log": "Fehler beim Laden des Konfliktprotokolls... Neustart.",
        "no_items_in_conflict_log": "Keine Elemente im Konfliktprotokoll",
        "error": "Fehler...",
        "error_in_application": "Fehler in der Anwendung. Neustart.",
        "loading_friend_info": "Fehler beim Laden von Freundinformationen... Neustart.",
        "confirming_friend": "Fehler beim Bestätigen des Freundes. Bitte erneut versuchen.",
        "friend_already_exists": "Freund mit dieser E-Mail-Adresse existiert bereits",
        "creating_friend": "Fehler beim Erstellen des Freundes. Bitte erneut versuchen.",
        "loading_global_items": "Fehler beim Laden globaler Elemente... Neustart.",
        "loading_global_item": "Fehler beim Laden von globalen Elementinformationen... Neustart.",
        "no_global_items_available": "Keine globalen Elemente verfügbar",
        "loading_list_info":"Fehler beim Laden von Listeninformationen... Neustart.",
        "cannot_use_name_existing_item": "Name des vorhandenen Elements in der Listen-Gruppe kann nicht verwendet werden",
        "cannot_use_name_existing_globalitem": "Name des vorhandenen globalen Elements kann nicht verwendet werden",
        "updating_item": "Fehler beim Aktualisieren des Elements. Bitte erneut versuchen.",
        "adding_category": "Fehler beim Hinzufügen der Kategorie. Bitte erneut versuchen.",
        "uom_exists": "Angeforderte Maßeinheit existiert bereits. Bitte erneut versuchen.",
        "uom_length_error": "Maßeinheiten müssen 2 Zeichen lang sein. Bitte erneut versuchen.",
        "no_uom_description": "Keine Beschreibung der Maßeinheit eingegeben. Bitte erneut versuchen.",
        "uom_description_exists": "Angeforderte Beschreibung der Maßeinheit existiert bereits. Bitte erneut versuchen.",
        "no_uom_plural_description":"Keine Mehrzahlbeschreibung der Maßeinheit eingegeben. Bitte erneut versuchen.",
        "uom_plural_description_exists":"Angeforderte Mehrzahlbeschreibung der Maßeinheit existiert bereits. Bitte erneut versuchen.",
        "adding_uom": "Fehler beim Hinzufügen der Maßeinheit. Bitte erneut versuchen.",
        "adding_to_list": "Fehler beim Hinzufügen zur Liste",
        "item_exists_current_list": "Element existiert bereits in der aktuellen Liste",
        "adding_item": "Fehler beim Hinzufügen des Elements. Bitte erneut versuchen.",
        "checking_items_list_group_header": "Überprüfen von Elementen in der Listen-Gruppe",
        "checking_items_list_group_detail": "Warnung: Sie überprüfen Elemente in der Listen-Gruppe, während Sie sich im Listen-Gruppen-Modus befinden. Normalerweise wechseln Sie zuerst zur Einkaufsliste, um diese Änderungen vorzunehmen. Fortfahren?",
        "updating_item_completed": "Fehler beim Aktualisieren des abgeschlossenen Status. Bitte erneut versuchen.",
        "deleting_items_list": "Fehler beim Löschen von Elementen aus der Liste. Bitte erneut versuchen.",
        "please_create_list_before_adding_items":"Bitte erstellen Sie mindestens eine Liste, bevor Sie Elemente hinzufügen.",
        "no_items_on_list": "Keine Elemente in der Liste",
        "must_select_valid_listgroup_id": "Gültige Listen-Gruppen-ID auswählen",
        "creating_updating_list": "Fehler beim Erstellen/Aktualisieren der Liste. Bitte erneut versuchen.",
        "could_not_delete_list": "Die Liste konnte nicht gelöscht werden",
        "unable_remove_list_all_items":"Kann die Liste nicht von allen Elementen entfernen",
        "loading_listgroup": "Fehler beim Laden von Informationen zur Listen-Gruppe... Neustart.",
        "creating_updating_listgroup":"Fehler beim Erstellen/Aktualisieren der Listen-Gruppe",
        "could_not_delete_listgroup":"Die Listen-Gruppe konnte nicht gelöscht werden",
        "unable_remove_listgroup_items":"Fehler beim Entfernen der Listen-Gruppe von allen Elementen",
        "delete_listgroup": "Listen-Gruppe kann nicht gelöscht werden",
        "delete_listgroup_detail": "Sie können die letzte verbleibende Listen-Gruppe, bei der Sie der Eigentümer sind, nicht löschen.",
        "loading_listgroups": "Fehler beim Laden von Informationen zu Listen-Gruppen... Neustart.",
        "app_not_support_newer_schema":"Diese Anwendung unterstützt eine erkannte neuere Version des Schemas nicht. Bitte aktualisieren Sie die App und versuchen Sie es erneut.",
        "app_not_support_newer_schema_short":"App unterstützt das Datenbankschema nicht",
        "warning": "Warnung",
        "different_database_schema":"Das Datenbankschema auf dem Server ist nicht dasselbe wie die lokale Kopie. Dies ist normal, wenn es ein signifikantes Anwendungs-Update gibt. Sie sollten Ihre lokale Kopie löschen, um fortzufahren. App wird beendet.",
        "different_database_schema_short": "Verschiedene Datenbankschemata lokal/remote",
        "server_no_unique_id":"Der Server ist falsch konfiguriert und hat keine eindeutige ID. Stellen Sie bitte sicher, dass der Server-Prozess läuft.",
        "server_no_unique_id_short":"Der Server hat keine eindeutige Datenbank-ID.",
        "different_database_unique_id":"Die Datenbank-Identifikation auf dem Server ist nicht dieselbe wie die lokale Kopie. Sie sollten Ihre lokale Kopie löschen, um fortzufahren. App wird beendet.",
        "invalid_authentication": "Ungültige Authentifizierung",
        "creating_user":"Fehler beim Erstellen des Benutzers",
        "invalid_data_entered":"Ungültige Daten eingegeben",
        "user_already_exists": "Benutzer existiert bereits",
        "must_enter_username_reset_password":"Benutzername muss eingegeben werden, um das Passwort zurückzusetzen",
        "database_server_not_available_choose_work_offline":"Der Datenbank-Server ist nicht verfügbar. Sie können offline arbeiten, und Ihre Änderungen werden synchronisiert, wenn Sie die App erneut starten und der Server verfügbar ist. Das Risiko von Konflikten erhöht sich so.",
        "loading_recipes": "Fehler beim Laden der Rezepte... Neustart.",
        "no_recipes_exist": "Es gibt noch keine Rezepte",
        "loading_recipe": "Fehler beim Laden des Rezepts... Neustart.",
        "duplicate_recipe_name": "Rezeptname existiert bereits.",
        "updating_recipe": "Fehler beim Aktualisieren des Rezepts.",
        "unable_delete_recipe": "Rezept konnte nicht gelöscht werden, bitte erneut versuchen.",
        "loading_recipe_import": "Fehler beim Laden der Rezept-Importseite... Neustart.",
        "loading_uom_info": "Fehler beim Laden der UOM-Informationen... Neustart.",
        "loading_uom": "Fehler beim Laden der UOM... Neustart.",
        "duplicate_uom_name": "Duplikat UOM existiert (Kurzcode oder Beschreibungen)",
        "must_enter_description": "Eine Beschreibung muss eingegeben werden",
        "must_enter_plural_description": "Eine Pluralbeschreibung muss eingegeben werden",
        "unable_remove_uom_items": "UOM kann nicht von Items entfernt werden",
        "unable_remove_uom_recipes": "UOM kann nicht von Rezepten entfernt werden",
        "unable_delete_uom": "UOM kann nicht gelöscht werden",
        "blank_alternate_uom": "UOM Alternate darf nicht leer sein",
        "duplicate_alt_uom": "Duplikate in UOM Alternate-Liste",
        "picking_import_file": "Fehler beim Auswählen der Rezept-Importdatei",
        "no_import_file_selected": "Keine Rezept-Importdatei ausgewählt",
        "recipe_item_not_selected_to_add": "Item {{recipeName}} wurde nicht zum Hinzufügen ausgewählt.",
        "no_item_found_update_recipe":"Es wurde kein Artikel zum Aktualisieren für {{itemName}} gefunden",
        "uom_mismatch_recipe_import_note":"WARNUNG: Einheitenumwandlung stimmt beim Import von Rezepten nicht überein. Einkaufsmenge/UoM ist {{quantity}} {{uom}}",
        "updating_item_x": "Fehler beim Aktualisieren des Artikels {{name}}",
        "uom_mismatch_recipe_import_status":"WARNUNG: Einheitenumwandlung stimmt bei {{name}} nicht überein (Einkaufs-UoM ist {{shoppingUom}}, Liste war {{listUom}}) - bitte überprüfen.",
        "recipe_note_overwritten":"WARNUNG: Hinweis auf Artikel wurde mit Rezept-Hinweis überschrieben",
        "adding_item_x":"Fehler beim Hinzufügen des Artikels {{name}}",
        "invalid_zip_file": "Ungültige Zip-Datei",
        "zip_not_contain_recipe":"Mindestens eine Zip-Datei enthielt keine Rezepteingabe",
        "invalid_recipe_json":"Ungültiges Rezept-JSON in Datei",
        "nothing_to_load": "Nichts zu laden",
        "could_not_import_recipe_dup":"Konnte nicht importieren: {{recipe}} - Duplikat",
        "could_not_match_uom":"Konnte UOM nicht finden. Original war: {{name}} oder {{pluralName}}",
        "must_enter_a_plural_name":"Es muss ein Pluralname eingegeben werden",
        "account_creation_disabled":"Kontoerstellung deaktiviert",
        "updating_uom": "Fehler beim Aktualisieren der Maßeinheit",
        "database_server_not_available": "Datenbankserver nicht verfügbar",
        "press_button_work_offline_as_user": "Drücken Sie die Schaltfläche unten, um als Benutzer offline zu arbeiten:",
        "server_not_available_nor_cached_creds": "Der Server ist nicht verfügbar, um sich anzumelden. Es sind keine zwischengespeicherten Anmeldeinformationen vorhanden (oder die Anmeldeinformationen waren ungültig), um die App lokal verwenden zu können. Bitte melden Sie sich erneut an, wenn der Server verfügbar ist.",
        "unknown_login_state": "Unbekannter Anmeldestatus. Bitte starten Sie die Anwendung neu.",
        "friends_server_unavailable": "Freundesdaten können nicht angezeigt oder aktualisiert werden. Die API und/oder die Datenbank sind nicht verfügbar.",
        "app_already_running": "Bereits in einer anderen Sitzung ausgeführt, bitte setze die Verwendung der App dort fort.",
        "listgroup_already_exists": "Es gibt bereits eine Listen-Gruppe mit diesem Namen.",
        "list_already_exists": "Es gibt bereits eine Liste mit diesem Namen.",
        "cannot_create_category_no_listgroup_selected": "Es kann keine Kategorie erstellt werden, wenn keine Listen-Gruppe ausgewählt ist.",
        "cannot_create_uom_no_listgroup_selected": "Es kann keine Maßeinheit erstellt werden, wenn keine Listen-Gruppe ausgewählt ist.",
        "header_adding_item": "Fehler beim Hinzufügen eines vorhandenen Elements",
        "header_warning_adding_item": "Warnung beim Hinzufügen eines vorhandenen Elements",
        "warning_none_set_active": "Basierend auf den Einstellungen ist das Element zwar hinzugefügt worden, ist jedoch derzeit auf keiner der Listen aktiv. Dies könnte daran liegen, dass die Kategorie in den Listen nicht aktiv ist.",
        "deleting_listgroup": "Fehler beim Löschen der Listen-Gruppe",
        "deleting_listgroup_detail": "Die letzte verbleibende Listen-Gruppe kann nicht gelöscht werden. Fügen Sie bitte zuerst eine weitere Gruppe hinzu, wenn Sie wirklich löschen möchten.",
        "need_one_alexa_default": "Es sollte eine Listen-Gruppe als Standard für Alexa markiert sein. Derzeit sind {{count}} Listen-Gruppen als Standard markiert, wenn Sie diese Liste speichern. Wenn keine spezifiziert ist, verwendet Alexa die erste verfügbare Listen-Gruppe. Wenn mehr als eine spezifiziert ist, wird die alphabetisch erste verwendet.",
        "different_server_local_app_versions": "Diese Anwendungsversion unterscheidet sich von der auf dem Server. Sie könnten Fehler auftreten, wenn Sie fortfahren, und sollten entweder den Client oder den Server aktualisieren, um sicherzustellen, dass sie synchron sind, bevor Sie fortfahren.",
        "different_server_local_app_versions_short": "Anwendungsversion auf entferntem Server unterschiedlich"        
    },
    "itemtext": {
        "item_is_on_these_lists": "Artikel ist auf diesen Listen:",
        "highlighted_lists_diff_values": "Hervorgehobene Listen haben unterschiedliche Werte auf der Ebene von Artikel-Liste",
        "list_values": "Listenwerte",
        "item_was_purchased_from_here": "Artikel wurde hier gekauft"
    },
    "category": {
        "bakery": "Bäckerei",
        "deli": "Feinkost",
        "dairy": "Molkerei",
        "baking": "Backen",
        "cannedmeat": "Dosenfleisch",
        "chips": "Snacks: Chips",
        "pretzels": "Snacks: Brezeln",
        "cookies": "Snacks: Kekse",
        "crackers": "Snacks: Cracker",
        "nuts": "Snacks: Nüsse",
        "cannedvegetables": "Dosengemüse",
        "cannedsoup": "Dosensuppen",
        "paperproducts": "Papierprodukte",
        "juice": "Saft",
        "soda": "Limonaden",
        "pharmacy": "Medikamente",
        "beverages": "Getränke",
        "frozentreats": "Gefrorene Leckereien/Eiscreme",
        "frozendinners": "Tiefkühlgerichte",
        "frozenvegetables": "Tiefkühlgemüse",
        "frozenother": "Tiefkühl: Andere",
        "laundry": "Waschmittel",
        "meat": "Fleisch",
        "seafood": "Meeresfrüchte",
        "produce": "Obst/Gemüse",
        "condiments": "Gewürze",
        "mexican": "Mexikanisch",
        "asian": "Asiatisch",
        "italian": "Italienisch",
        "cereal": "Getreide",
        "bread" : "Brot",
        "alcohol" : "Alkohol",
        "floral": "Blumen",
        "petfood": "Tierfutter",
        "cleaning": "Reinigung",
        "hair": "Haarprodukte",
        "auto": "Auto",
        "office": "Bürobedarf",
        "beauty": "Schönheitsprodukte",
        "breakfast": "Frühstück (anderes)",
        "coffeetea": "Kaffee/Tee",
    },
    "uom": {
        "EA_one": "Jedes",
        "EA_other": "Jede",
        "X2_one": "Bündel",
        "X2_other": "Bündel",
        "OZ_one": "Unze",
        "OZ_other": "Unzen",
        "FO_one": "Flüssigunze",
        "FO_other": "Flüssigunzen",
        "LB_one": "Pfund",
        "LB_other": "Pfund",
        "GA_one": "Gallone",
        "GA_other": "Gallonen",
        "GH_one": "Halbe Gallone",
        "GH_other": "Halbe Gallonen",
        "QT_one": "Quart",
        "QT_other": "Quarts",
        "LT_one": "Liter",
        "LT_other": "Liter",
        "ML_one": "Milliliter",
        "ML_other": "Milliliter",
        "KG_one": "Kilogramm",
        "KG_other": "Kilogramm",
        "GR_one": "Gramm",
        "GR_other": "Gramm",
        "BX_one": "Box",
        "BX_other": "Boxen",
        "BG_one": "Tüte",
        "BG_other": "Tüten",
        "BO_one": "Flasche",
        "BO_other": "Flaschen",
        "CA_one": "Kiste",
        "CA_other": "Kisten",
        "CN_one": "Dose",
        "CN_other": "Dosen",
        "CU_one": "Tasse",
        "CU_other": "Tassen",
        "CT_one": "Karton",
        "CT_other": "Kartons",
        "CH_one": "Behälter",
        "CH_other": "Behälter",
        "DZ_one": "Dutzend",
        "DZ_other": "Dutzend",
        "JR_one": "Glas",
        "JR_other": "Gläser",
        "X8_one": "Laib",
        "X8_other": "Laibe",
        "Y1_one": "Scheibe",
        "Y1_other": "Scheiben",
        "15_one": "Stange",
        "15_other": "Stangen",
        "PC_one": "Stück",
        "PC_other": "Stück",
        "PK_one": "Packung",
        "PK_other": "Packungen",
        "PT_one": "Pint",
        "PT_other": "Pints",
        "RL_one": "Rolle",
        "RL_other": "Rollen",
        "Z-CL_one": "Knoblauchzehe",
        "Z-CL_other": "Knoblauchzehen",
        "Y2_one": "Esslöffel",
        "Y2_other": "Esslöffel",
        "Y3_one": "Teelöffel",
        "Y3_other": "Teelöffel"
    },
    "globalitem": 
    {
        "bananas_one": "Bananen",
        "bananas_other": "Bananen",
        "cosmiccrisp_one": "Cosmic Crisp-Äpfel",
        "cosmiccrisp_other": "Cosmic Crisp-Äpfel",
        "blueberries_one": "Blaubeeren",
        "blueberries_other": "Blaubeeren",
        "blackberries_one": "Brombeeren",
        "blackberries_other": "Brombeeren",
        "strawberries_one": "Erdbeeren",
        "strawberries_other": "Erdbeeren",
        "raspberries_one": "Himbeeren",
        "raspberries_other": "Himbeeren",
        "apples_one": "Äpfel",
        "apples_other": "Äpfel",
        "redpeppers_one": "Rote Paprika",
        "redpeppers_other": "Rote Paprika",
        "greenpeppers_one": "Grüne Paprika",
        "greenpeppers_other": "Grüne Paprika",
        "orangepeppers_one": "Orangefarbene Paprika",
        "orangepeppers_other": "Orangefarbene Paprika",
        "potatoes_one": "Kartoffeln",
        "potatoes_other": "Kartoffeln",
        "sweetpotatoes_one": "Süßkartoffeln",
        "sweetpotatoes_other": "Süßkartoffeln",
        "babyspinach_one": "Baby-Spinat",
        "babyspinach_other": "Baby-Spinat",
        "iceberglettuce_one": "Eisbergsalat",
        "iceberglettuce_other": "Eisbergsalat",
        "mixedgreens_one": "Gemischter Salat",
        "mixedgreens_other": "Gemischter Salat",
        "redseedlessgrapes_one": "Rote kernlose Trauben",
        "redseedlessgrapes_other": "Rote kernlose Trauben",
        "greenseedlessgrapes_one": "Grüne kernlose Trauben",
        "greenseedlessgrapes_other": "Grüne kernlose Trauben",
        "avocado_one": "Avocado",
        "avocado_other": "Avocado",
        "mandarins_one": "Mandarinen",
        "mandarins_other": "Mandarinen",
        "tangerines_one": "Mandarinen",
        "tangerines_other": "Mandarinen",
        "orangesseedless_one": "Orangen",
        "orangesseedless_other": "Orangen",
        "lemon_one": "Zitrone",
        "lemon_other": "Zitrone",
        "lime_one": "Limette",
        "lime_other": "Limette",
        "canteloupe_one": "Honigmelone",
        "canteloupe_other": "Honigmelone",
        "honeydewmelon_one": "Honigmelone",
        "honeydewmelon_other": "Honigmelone",
        "watermelon_one": "Wassermelone",
        "watermelon_other": "Wassermelone",
        "seedlesswatermelon_one": "Wassermelone kernlos",
        "seedlesswatermelon_other": "Wassermelone kernlos",
        "cherries_one": "Kirschen",
        "cherries_other": "Kirschen",
        "pineapple_one": "Ananas",
        "pineapple_other": "Ananas",
        "mango_one": "Mango",
        "mango_other": "Mango",
        "kiwi_one": "Kiwi-Frucht",
        "kiwi_other": "Kiwi-Frucht",
        "pear_one": "Birne",
        "pear_other": "Birne",
        "peaches_one": "Pfirsiche",
        "peaches_other": "Pfirsiche",
        "plum_one": "Pflaume",
        "plum_other": "Pflaume",
        "onion_one": "Zwiebel",
        "onion_other": "Zwiebel",
        "celery_one": "Sellerie",
        "celery_other": "Sellerie",
        "babycarrots_one": "Baby-Karotten",
        "babycarrots_other": "Baby-Karotten",
        "tomatoes_one": "Tomaten",
        "tomatoes_other": "Tomaten",
        "grapetomatoes_one": "Kirschtomaten",
        "grapetomatoes_other": "Kirschtomaten",
        "cucumber_one": "Gurke",
        "cucumber_other": "Gurke",
        "carrots_one": "Karotten",
        "carrots_other": "Karotten",
        "broccoli_one": "Brokkoli",
        "broccoli_other": "Brokkoli",
        "mushrooms_one": "Pilze",
        "mushrooms_other": "Pilze",
        "asparagus_one": "Spargel",
        "asparagus_other": "Spargel",
        "garlic_one": "Knoblauch",
        "garlic_other": "Knoblauch",
        "cilantro_one": "Koriander",
        "cilantro_other": "Koriander",
        "corn_one": "Mais",
        "corn_other": "Mais",
        "cauliflower_one": "Blumenkohl",
        "cauliflower_other": "Blumenkohl",
        "jalapenopepper_one": "Jalapeno",
        "jalapenopepper_other": "Jalapeno",
        "brusselsprouts_one": "Rosenkohl",
        "brusselsprouts_other": "Rosenkohl",
        "parsley_one": "Petersilie",
        "parsley_other": "Petersilie",
        "ginger_one": "Ingwer",
        "ginger_other": "Ingwer",
        "greenbeans_one": "Grüne Bohnen",
        "greenbeans_other": "Grüne Bohnen",
        "squash_one": "Kürbis",
        "squash_other": "Kürbis",
        "zucchini_one": "Zucchini",
        "zucchini_other": "Zucchini",
        "kale_one": "Grünkohl",
        "kale_other": "Grünkohl",
        "basil_one": "Basilikum",
        "basil_other": "Basilikum",
        "radishes_one": "Radieschen",
        "radishes_other": "Radieschen",
        "cabbage_one": "Kohl",
        "cabbage_other": "Kohl",
        "eggplant_one": "Aubergine",
        "eggplant_other": "Aubergine",
        "spinach_one": "Spinat",
        "spinach_other": "Spinat",
        "beets_one": "Rote Beete",
        "beets_other": "Rote Beete",
        "serranopeppers_one": "Serrano-Chili",
        "serranopeppers_other": "Serrano-Chili",
        "romainelettuce_one": "Romanasalat",
        "romainelettuce_other": "Romanasalat",
        "coleslaw_one": "Coleslaw",
        "coleslaw_other": "Coleslaw",
        "shreddedlettuce_one": "Geschnittener Salat",
        "shreddedlettuce_other": "Geschnittener Salat",
        "salami_one": "Salami",
        "salami_other": "Salami",
        "pepperoni_one": "Pepperoni",
        "pepperoni_other": "Pepperoni",
        "deliham_one": "Schinken",
        "deliham_other": "Schinken",
        "slicedswisscheese_one": "Schweizer Käse (in Scheiben)",
        "slicedswisscheese_other": "Schweizer Käse (in Scheiben)",
        "bologna_one": "Bologna-Wurst",
        "bologna_other": "Bologna-Wurst",
        "slicedturkey_one": "Truthahn (in Scheiben)",
        "slicedturkey_other": "Truthahn (in Scheiben)",
        "slicedprovolone_one": "Provolone (in Scheiben)",
        "slicedprovolone_other": "Provolone (in Scheiben)",
        "rotisseriechicken_one": "Hähnchen vom Drehspieß",
        "rotisseriechicken_other": "Hähnchen vom Drehspieß",
        "wholemilk_one": "Vollmilch",
        "wholemilk_other": "Vollmilch",
        "1percentmilk_one": "1% Milch",
        "1percentmilk_other": "1% Milch",
        "2percentmilk_one": "2% Milch",
        "2percentmilk_other": "2% Milch",
        "skimmilk_one": "Magermilch",
        "skimmilk_other": "Magermilch",
        "orangejuice_one": "Orangensaft",
        "orangejuice_other": "Orangensaft",
        "freshlemonade_one": "Frische Limonade",
        "freshlemonade_other": "Frische Limonade",
        "butter_one": "Butter",
        "butter_other": "Butter",
        "eggs_one": "Eier",
        "eggs_other": "Eier",
        "sourcream_one": "Saure Sahne",
        "sourcream_other": "Saure Sahne",
        "cottagecheese_one": "Hüttenkäse",
        "cottagecheese_other": "Hüttenkäse",
        "whippedcream_one": "Schlagsahne",
        "whippedcream_other": "Schlagsahne",
        "whitebread_one": "Weißbrot",
        "whitebread_other": "Weißbrot",
        "wheatbread_one": "Weizenbrot",
        "wheatbread_other": "Weizenbrot",
        "cinnamonraisinbread_one": "Zimt-Rosinen-Brot",
        "cinnamonraisinbread_other": "Zimt-Rosinen-Brot",
        "englishmuffins_one": "Englische Muffins (natur)",
        "englishmuffins_other": "Englische Muffins (natur)",
        "englishmuffinscinnamonraisin_one": "Englische Muffins (Zimt-Rosine)",
        "englishmuffinscinnamonraisin_other": "Englische Muffins (Zimt-Rosine)",
        "frenchbread_one": "Baguette",
        "frenchbread_other": "Baguette",
        "italianbread_one": "Italienisches Brot",
        "italianbread_other": "Italienisches Brot",
        "blueberrymuffins_one": "Heidelbeer-Muffins",
        "blueberrymuffins_other": "Heidelbeer-Muffins",
        "cinnamonmuffins_one": "Zimt-Muffins",
        "cinnamonmuffins_other": "Zimt-Muffins",
        "chocolatechipmuffins_one": "Schoko-Chip-Muffins",
        "chocolatechipmuffins_other": "Schoko-Chip-Muffins",
        "groundbeef_one": "Rinderhackfleisch",
        "groundbeef_other": "Rinderhackfleisch",
        "filetmignon_one": "Filet Mignon",
        "filetmignon_other": "Filet Mignon",
        "stripsteak_one": "Strip Steak",
        "stripsteak_other": "Strip Steak",
        "chickenbreasts_one": "Hähnchenbrust",
        "chickenbreasts_other": "Hähnchenbrust",
        "chickendrumsticks_one": "Hähnschenschenkel",
        "chickendrumsticks_other": "Hähnschenschenkel",
        "italiansausage_one": "Italienische Wurst",
        "italiansausage_other": "Italienische Wurst",
        "salmon_one": "Lachs",
        "salmon_other": "Lachs",
        "freshshrimp_one": "Frische Garnelen",
        "freshshrimp_other": "Frische Garnelen",
        "chips_one": "Chips",
        "chips_other": "Chips",
        "bbqchips_one": "BBQ Chips",
        "bbqchips_other": "BBQ Chips",
        "sourcreamonionchips_one": "Sour Cream & Onion Chips",
        "sourcreamonionchips_other": "Sour Cream & Onion Chips",
        "tortillachips_one": "Tortilla Chips",
        "tortillachips_other": "Tortilla Chips",
        "pretzels_one": "Brezeln",
        "pretzels_other": "Brezeln",
        "minipretzels_one": "Mini-Brezeln",
        "minipretzels_other": "Mini-Brezeln",
        "saltines_one": "Salzgebäck",
        "saltines_other": "Salzgebäck",
        "almonds_one": "Mandeln",
        "almonds_other": "Mandeln",
        "peanuts_one": "Erdnüsse",
        "peanuts_other": "Erdnüsse",
        "honeyroastedpeanuts_one": "In Honig geröstete Erdnüsse",
        "honeyroastedpeanuts_other": "In Honig geröstete Erdnüsse",
        "mixednuts_one": "Gemischte Nüsse",
        "mixednuts_other": "Gemischte Nüsse",
        "walnuts_one": "Walnüsse",
        "walnuts_other": "Walnüsse",
        "pistachios_one": "Pistazien",
        "pistachios_other": "Pistazien",
        "cannedcorn_one": "Dosenmais",
        "cannedcorn_other": "Dosenmais",
        "cannedcreamcorn_one": "Dosenmais mit Sahne",
        "cannedcreamcorn_other": "Dosenmais mit Sahne",
        "cannedgreenbeans_one": "Grüne Bohnen (Dose)",
        "cannedgreenbeans_other": "Grüne Bohnen (Dose)",
        "cannedpeas_one": "Dosenerbsen",
        "cannedpeas_other": "Dosenerbsen",
        "cannedmushroomssliced_one": "Dosenpilze (geschnitten)",
        "cannedmushroomssliced_other": "Dosenpilze (geschnitten)",
        "cannedmushrooms_one": "Dosenpilze",
        "cannedmushrooms_other": "Dosenpilze",
        "cannedtomatosauce_one": "Tomatensauce (Dose)",
        "cannedtomatosauce_other": "Tomatensauce (Dose)",
        "cannedtomatopaste_one": "Tomatenmark",
        "cannedtomatopaste_other": "Tomatenmark",
        "cannedpetitedicedtomatoes_one": "Dosentomaten (in Stücken)",
        "cannedpetitedicedtomatoes_other": "Dosentomaten (in Stücken)",
        "tomatosoup_one": "Tomatensuppe",
        "tomatosoup_other": "Tomatensuppe",
        "chickennoodlesoup_one": "Hühnernudeltopf",
        "chickennoodlesoup_other": "Hühnernudeltopf",
        "creamofmushroomsoup_one": "Pilzrahmsuppe",
        "creamofmushroomsoup_other": "Pilzrahmsuppe",
        "creamofchickensoup_one": "Hühnerrahmsuppe",
        "creamofchickensoup_other": "Hühnerrahmsuppe",
        "vegetablesoup_one": "Gemüsesuppe",
        "vegetablesoup_other": "Gemüsesuppe",
        "bluecheesedressing_one": "Blauschimmelkäse-Salatdressing",
        "bluecheesedressing_other": "Blauschimmelkäse-Salatdressing",
        "ranchdressing_one": "Ranchdressing",
        "ranchdressing_other": "Ranchdressing",
        "balsalmicvinaigrettedressing_one": "Balsamico-Vinaigrette-Salatdressing",
        "balsalmicvinaigrettedressing_other": "Balsamico-Vinaigrette-Salatdressing",
        "worcestershiresauce_one": "Worcestershire-Soße",
        "worcestershiresauce_other": "Worcestershire-Soße",
        "soysauce_one": "Sojasoße",
        "soysauce_other": "Sojasoße",
        "applejuice_one": "Apfelsaft",
        "applejuice_other": "Apfelsaft",
        "whitegrapejuice_one": "Traubensaft (Weiß)",
        "whitegrapejuice_other": "Traubensaft (Weiß)",
        "grapejuice_one": "Traubensaft (Rot)",
        "grapejuice_other": "Traubensaft (Rot)",
        "tomatojuice_one": "Tomatensaft",
        "tomatojuice_other": "Tomatensaft",
        "cranberryjuice_one": "Cranberrysaft",
        "cranberryjuice_other": "Cranberrysaft",
        "coke_one": "Coca Cola",
        "coke_other": "Coca Cola",
        "dietcoke_one": "Coca Cola Light",
        "dietcoke_other": "Coca Cola Light",
        "pepsi_one": "Pepsi",
        "pepsi_other": "Pepsi",
        "dietpepsi_one": "Pepsi Light",
        "dietpepsi_other": "Pepsi Light",
        "bottledwater_one": "Wasser",
        "bottledwater_other": "Wasser",
        "seltzerwater_one": "Sprudelwasser",
        "seltzerwater_other": "Sprudelwasser",
        "sparklingwater_one": "Sprudelwasser",
        "sparklingwater_other": "Sprudelwasser",
        "vanillaicecream_one": "Vanilleeis",
        "vanillaicecream_other": "Vanilleeis",
        "chocolateicecream_one": "Schokoladeneis",
        "chocolateicecream_other": "Schokoladeneis",
        "neopolitanicecream_one": "Fürst-Pückler Eis",
        "neopolitanicecream_other": "Fürst-Pückler Eis",
        "frozenspinach_one": "Tiefgefrorener Spinat",
        "frozenspinach_other": "Tiefgefrorener Spinat",
        "frozenbroccoli_one": "Tiefgefrorener Brokkoli",
        "frozenbroccoli_other": "Tiefgefrorener Brokkoli",
        "frozenpetitebroccoliflorets_one": "Tiefgefrorene Brokkolirosen",
        "frozenpetitebroccoliflorets_other": "Tiefgefrorene Brokkolirosen",
        "frozencorn_one": "Tiefgefrorener Mais",
        "frozencorn_other": "Tiefgefrorener Mais",
        "frozengreenbeans_one": "Tiefgefrorene Grüne Bohnen",
        "frozengreenbeans_other": "Tiefgefrorene Grüne Bohnen",
        "frozenpeas_one": "Tiefgefrorene Erbsen",
        "frozenpeas_other": "Tiefgefrorene Erbsen",
        "frozenstrawberries_one": "Tiefgefrorene Erdbeeren",
        "frozenstrawberries_other": "Tiefgefrorene Erdbeeren",
        "frozenblueberries_one": "Tiefgefrorene Blaubeeren",
        "frozenblueberries_other": "Tiefgefrorene Blaubeeren",
        "frozenwaffles_one": "Tiefgefrorene Waffeln",
        "frozenwaffles_other": "Tiefgefrorene Waffeln",
        "frozenpancakes_one": "Tiefgefrorene Pfannkuchen",
        "frozenpancakes_other": "Tiefgefrorene Pfannkuchen",
        "frozentatertots_one": "Tiefgefrorene Kroketten",
        "frozentatertots_other": "Tiefgefrorene Kroketten",
        "frozenfrenchfries_one": "Tiefgefrorene Pommes Frites",
        "frozenfrenchfries_other": "Tiefgefrorene Pommes Frites",
        "pierogies_one": "Piroggen",
        "pierogies_other": "Piroggen",
        "ketchup_one": "Ketchup",
        "ketchup_other": "Ketchup",
        "bbqsauce_one": "BBQ-Sauce",
        "bbqsauce_other": "BBQ-Sauce",
        "blackolives_one": "Schwarze Oliven",
        "blackolives_other": "Schwarze Oliven",
        "vegetableoil_one": "Pflanzenöl",
        "vegetableoil_other": "Pflanzenöl",
        "oliveoil_one": "Olivenöl",
        "oliveoil_other": "Olivenöl",
        "semisweetchocchips_one": "Vollmilch Schokoladenstückchen",
        "semisweetchocchips_other": "Vollmilch Schokoladenstückchen",
        "darkchocchips_one": "Dunkle Schokoladenstückchen",
        "darkchocchips_other": "Dunkle Schokoladenstückchen",
        "marshmallows_one": "Marshmallows",
        "marshmallows_other": "Marshmallows",
        "cornbreadmix_one": "Maisbrotmischung",
        "cornbreadmix_other": "Maisbrotmischung",
        "flour_one": "Mehl",
        "flour_other": "Mehl",
        "chiaseeds_one": "Chiasamen",
        "chiaseeds_other": "Chiasamen",
        "flaxseed_one": "Leinsamen",
        "flaxseed_other": "Leinsamen",
        "bakingsoda_one": "Natron",
        "bakingsoda_other": "Natron",
        "bakingpowder_one": "Backpulver",
        "bakingpowder_other": "Backpulver",
        "wheatflour_one": "Weizenmehl",
        "wheatflour_other": "Weizenmehl",
        "sugar_one": "Zucker",
        "sugar_other": "Zucker",
        "confectionerssugar_one": "Puderzucker",
        "confectionerssugar_other": "Puderzucker",
        "cinnamon_one": "Zimt",
        "cinnamon_other": "Zimt",
        "salt_one": "Salz",
        "salt_other": "Salz",
        "groundpepper_one": "Gemahlener Pfeffer",
        "groundpepper_other": "Gemahlener Pfeffer",
        "chilipowder_one": "Chilipulver",
        "chilipowder_other": "Chilipulver",
        "basildried_one": "Basilikum (getrocknet)",
        "basildried_other": "Basilikum (getrocknet)",
        "oregano_one": "Oregano",
        "oregano_other": "Oregano",
        "nutmeg_one": "Muskatnuss",
        "nutmeg_other": "Muskatnuss",
        "groundginger_one": "Gemahlener Ingwer",
        "groundginger_other": "Gemahlener Ingwer",
        "spaghettisauce_one": "Spaghettisoße",
        "spaghettisauce_other": "Spaghettisoße",
        "pizzasauce_one": "Pizzasauce",
        "pizzasauce_other": "Pizzasauce",
        "spaghetti_one": "Spaghetti",
        "spaghetti_other": "Spaghetti",
        "pastashells_one": "Muschelnudeln",
        "pastashells_other": "Muschelnudeln",
        "pastabowtie_one": "Farfalle",
        "pastabowtie_other": "Farfalle",
        "pastarotini_one": "Fussili",
        "pastarotini_other": "Fussili",
        "pastatricolorrotini_one": "Fussili Tricolore",
        "pastatricolorrotini_other": "Fussili Tricolore",
        "cannedtunawater_one": "Dosen Thunfisch in Wasser",
        "cannedtunawater_other": "Dosen Thunfisch in Wasser",
        "cannedtunaoil_one": "Dosen Thunfisch in Öl",
        "cannedtunaoil_other": "Dosen Thunfisch in Öl",
        "cannedsalmon_one": "Dosenlachs",
        "cannedsalmon_other": "Dosenlachs",
        "salmonpouch_one": "Räucherlachs",
        "salmonpouch_other": "Räucherlachs",
        "dishwashingliquid_one": "Spülmittel",
        "dishwashingliquid_other": "Spülmittel",
        "dishwashingdetergent_one": "Geschirrspülmittel",
        "dishwashingdetergent_other": "Geschirrspülmittel",
        "sponges_one": "Schwämme",
        "sponges_other": "Schwämme",
        "toiletbowlcleaner_one": "Toilettenreiniger",
        "toiletbowlcleaner_other": "Toilettenreiniger",
        "handsoap_one": "Handseife",
        "handsoap_other": "Handseife",
        "bleachcleaner_one": "Bleichmittel",
        "bleachcleaner_other": "Bleichmittel",
        "bleach_one": "Bleichmittel",
        "bleach_other": "Bleichmittel",
        "laundrydetergent_one": "Waschmittel",
        "laundrydetergent_other": "Waschmittel",
        "fabricsoftener_one": "Weichspüler",
        "fabricsoftener_other": "Weichspüler",
        "colorbleach_one": "Farbbleichmittel",
        "colorbleach_other": "Farbbleichmittel",
        "laundrypods_one": "Waschmittel-Pods",
        "laundrypods_other": "Waschmittel-Pods",
        "disinfectingwipes_one": "Desinfektionstücher",
        "disinfectingwipes_other": "Desinfektionstücher",
        "steelwoolpads_one": "Stahlwolle-Pads",
        "steelwoolpads_other": "Stahlwolle-Pads",
        "drainclogremover_one": "Abflussreiniger",
        "drainclogremover_other": "Abflussreiniger",
        "bandaids_one": "Pflaster",
        "bandaids_other": "Pflaster",
        "cottonballs_one": "Wattepads",
        "cottonballs_other": "Wattepads",
        "nailpolishremover_one": "Nagellackentferner",
        "nailpolishremover_other": "Nagellackentferner",
        "notebook_one": "Notizbuch",
        "notebook_other": "Notizbuch",
        "scotchtape_one": "Klebeband",
        "scotchtape_other": "Klebeband",
        "maskingtape_one": "Abdeckband",
        "maskingtape_other": "Abdeckband",
        "packagingtape_one": "Verpackungsband",
        "packagingtape_other": "Verpackungsband",
        "copypaper_one": "Kopierpapier",
        "copypaper_other": "Kopierpapier",
        "superglue_one": "Sekundenkleber",
        "superglue_other": "Sekundenkleber",
        "glue_one": "Kleber",
        "glue_other": "Kleber",
        "crayons_one": "Buntstifte",
        "crayons_other": "Buntstifte",
        "pencils_one": "Bleistifte",
        "pencils_other": "Bleistifte",
        "pens_one": "Kugelschreiber",
        "pens_other": "Kugelschreiber",
        "envelopes_one": "Umschläge",
        "envelopes_other": "Umschläge",
        "paperclips_one": "Heftklammern",
        "paperclips_other": "Heftklammern",
        "rubberbands_one": "Gummibänder",
        "rubberbands_other": "Gummibänder",
        "scissors_one": "Schere",
        "scissors_other": "Schere",
        "papertowels_one": "Papiertücher",
        "papertowels_other": "Papiertücher",
        "toiletpaper_one": "Toilettenpapier",
        "toiletpaper_other": "Toilettenpapier",
        "tacoshells_one": "Taco-Schalen",
        "tacoshells_other": "Taco-Schalen",
        "salsajar_one": "Salsa (Glas)",
        "salsajar_other": "Salsa (Glas)",
        "tacoseasoning_one": "Taco-Gewürzmischung",
        "tacoseasoning_other": "Taco-Gewürzmischung",
        "tortillas_one": "Tortillas",
        "tortillas_other": "Tortillas",
        "raisinbran_one": "Raisin Bran",
        "raisinbran_other": "Raisin Bran",
        "maplesyrup_one": "Ahornsirup",
        "maplesyrup_other": "Ahornsirup",
        "windshieldwiperfluid_one": "Scheibenwischwasser",
        "windshieldwiperfluid_other": "Scheibenwischwasser",
        "coffee_one": "Kaffee",
        "coffee_other": "Kaffee",
        "coffeecreamer_one": "Kaffeeweißer",
        "coffeecreamer_other": "Kaffeeweißer",
        "tea_one": "Tee",
        "tea_other": "Tee",
        "clementines_one":"Clementine",
        "clementines_other":"Clementinen"
    }
}