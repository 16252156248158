export const es_translations = 
{
    "general": {
        "friends": "Amigos",
        "conflict_log": "Registro de Conflictos",
        "groceries_menu": "Menú de Compras",
        "lists": "Listas",
        "create_new_list": "Crear Nueva Lista",
        "manage_all_listgroups": "Administrar Todos los Grupos de Lista",
        "other_actions": "Acciones",
        "manage_categories": "Administrar Categorías",
        "manage_all_items": "Administrar Todos los Artículos",
        "view_global_items": "Ver Artículos Globales",
        "settings": "Configuraciones",
        "edit": "Editar",
        "editing": "Editando",
        "active": "Activo",
        "inactive": "Inactivo",
        "completed": "Completado",
        "stocked_here": "Abastecido Aquí",
        "category": "Categoría",
        "uncategorized": "Sin Categoría",
        "quantity": "Cantidad",
        "uom_abbrev": "UdM", // Abreviatura de Unidad de Medida
        "no_uom": "Sin UdM", // No especificó unidad de medida
        "times": "veces",
        "reset": "Reiniciar",
        "note": "Nota",
        "cancel": "Cancelar",
        "save": "Guardar",
        "add": "Agregar",
        "delete": "Eliminar",
        "name": "Nombre",
        "color": "Color",
        "needs_confirmed": "Necesita Confirmación",
        "requested": "Solicitado",
        "confirmed": "Confirmado",
        "needs_registering": "Necesita Registro",
        "take_picture_for_item":"Tomar una foto para su artículo",
        "loading_all_items": "Cargando todos los artículos...",
        "all_items": "Todos los Artículos",
        "loading_categories": "Cargando Categorías...",
        "categories": "Categorías",
        "loading_category": "Cargando Categoría...",
        "items_using_category_one": "Hay {{count}} artículo usando esta categoría.",
        "items_using_category_other": "Hay {{count}} artículos usando esta categoría.",
        "lists_using_category_one": "Hay {{count}} lista usando esta categoría.",
        "lists_using_category_other": "Hay {{count}} listas usando esta categoría.",
        "delete_this_list": "¿Eliminar esta lista: {{list}} ?",
        "really_delete_list": "¿Realmente desea eliminar esta lista?",
        "really_delete_list_extended": "¿Realmente desea eliminar esta lista? Toda la información en esta lista se perderá.",
        "all_list_info_lost": "Toda la información en esta lista se perderá.",
        "editing_category": "Editando Categoría: ",
        "loading_conflict_info": "Cargando Información de Conflicto...",
        "conflict_item": "Artículo en Conflicto",
        "from": "desde",
        "main_differences": "Diferencias Principales",
        "winner": "Ganador",
        "losers": "Perdedores",
        "return": "Regresar",
        "loading_conflict_log": "Cargando Registro de Conflictos...",
        "set_as_viewed": "Marcar como Visto",
        "loading_friends": "Cargando Amigos...",
        "email_sent": "Se ha enviado un correo electrónico a {{email}} para confirmar y crear su cuenta. La URL está aquí: {{url}} . Esto también se ha copiado al portapapeles.",
        "url": "URL",
        "confirm": "Confirmar",
        "prompt_register_friend": "No hay ningún usuario registrado actualmente con el correo electrónico {{email}}. ¿Quieres pedirles que se registren?",
        "adding_friend": "Agregando un nuevo amigo",
        "email_address_friend": "Dirección de correo electrónico del amigo a agregar",
        "user_not_found_send_registration": "Usuario no encontrado, ¿enviar solicitud de registro?",
        "send_registration": "Enviar registro",
        "url_registration_confirmation": "URL para confirmación de registro",
        "ok": "OK",
        "loading_global_item":"Cargando artículo global...",
        "undefined": "Indefinido",
        "viewing_global_item": "Viendo artículo global",
        "new_placeholder": "<NUEVO>",
        "default_category": "Categoría predeterminada",
        "default_uom": "Unidad de medida predeterminada",
        "go_back": "Volver",
        "loading_global_items": "Cargando artículos globales...",
        "global_items": "Artículos globales",
        "loading": "Cargando...",
        "logging_in": "Iniciando sesión...",
        "loading_item": "Cargando artículo...",
        "add_new_category": "Agregar nueva categoría",
        "add_mew_uom":"Agregar nueva unidad de medida",
        "plural_description": "Descripción plural",
        "delete_this_item": "¿Eliminar este artículo?",
        "really_delete_this_item":"¿Realmente desea eliminar este artículo?",
        "editing_item":"Editando artículo:",
        "take_photo": "Tomar foto",
        "delete_photo": "Eliminar foto",
        "change_here_change_all_below":"Cambiar los valores aquí cambiará en todas las listas siguientes",
        "item_note": "Nota del Artículo",
        "list_group": "Grupo de Lista",
        "loading_items": "Cargando Artículos",
        "continue_ignore": "Continuar/Ignorar",
        "items_on": "Artículos en :",
        "search": "Buscar",
        "delete_completed_items": "ELIMINAR ARTÍCULOS COMPLETADOS",
        "loading_list": "Cargando Lista...",
        "changing_selected_list":"Cambiando Lista Seleccionada",
        "list_updated_not_saved_still_change":"La lista se ha actualizado y no se ha guardado. ¿Aún quieres cambiar de lista?",
        "editing_list":"Editando Lista",
        "creating_new_list":"Creando Nueva Lista",
        "loading_list_group":"Cargando Grupo de Lista...",
        "lists_assigned_to_group":"Listas asignadas a este grupo:",
        "you_are_listgroup_owner": "Eres el propietario del grupo de lista",
        "is_listgroup_owner":"es el propietario del grupo de lista",
        "listgroup_shared_with_users":"El grupo de lista se comparte con estos otros usuarios:",
        "delete_this_listgroup":"¿Eliminar este grupo de lista: {{listgroup}} ?",
        "delete_this_listgroup_detail":"¿Realmente quieres eliminar este grupo de lista? Se perderá toda la información sobre este grupo de lista (listas y artículos).",
        "changing_selected_listgroup":"Cambiando Grupo de Lista Seleccionado",
        "changing_selected_listgroup_detail":"El grupo de lista se ha actualizado y no se ha guardado. ¿Aún quieres cambiar de grupo de lista?",
        "editing_listgroup":"Editando Grupo de Lista:",
        "creating_listgroup":"Creando nuevo grupo de lista",
        "loading_listgroups": "Cargando Grupos de Lista...",
        "listgroups": "Grupos de Lista",
        "all_lists": "Todas las Listas",
        "delete_exit":"Eliminar/Salir",
        "cancel_exit":"Cancelar/Salir",
        "request_password_reset":"Solicitar restablecimiento de contraseña",
        "press_reset_receive_email":"Presione restablecer para recibir un enlace de correo electrónico para restablecer su contraseña",
        "api_server_url":"URL del servidor API",
        "username": "Nombre de usuario",
        "password": "Contraseña",
        "email_address":"Dirección de correo electrónico",
        "email": "Correo electrónico",
        "fullname": "Nombre completo",
        "confirm_password": "Confirmar contraseña",
        "login": "Iniciar sesión",
        "reset_password": "Restablecer contraseña",
        "create_account": "Crear cuenta",
        "create":"Crear",
        "work_offline":"Trabajar sin conexión",
        "login_page": "Página de inicio de sesión",
        "remove_saved_credentials":"¿Desea eliminar sus credenciales guardadas? Esto hará que la aplicación se reinicie y le permitirá iniciar sesión nuevamente si lo desea.",
        "want_remove_local_database":"¿Desea eliminar la base de datos local? Esto hará que la aplicación se reinicie y le permitirá iniciar sesión nuevamente si lo desea. También sincronizará todos los datos del servidor de nuevo.",
        "app_info": "Información de la aplicación",
        "app_version": "Versión de la aplicación",
        "database_schema_version": "Versión del esquema de la base de datos",
        "user_info":"Información del usuario",
        "user_id": "ID de usuario",
        "logout":"Cerrar sesión",
        "delete_local_data":"Eliminar datos locales",
        "add_other_list_options":"Agregar a otras opciones de lista",
        "add_same_group_auto":"Agregar automáticamente en el mismo grupo",
        "add_same_categories_auto":"Agregar automáticamente con las mismas categorías",
        "dont_add_auto":"No agregar automáticamente",
        "other_settings":"Otras configuraciones",
        "remove_items_all_lists_purchased":"Eliminar elementos de todas las listas del grupo de listas cuando se marquen como comprados/completados",
        "delete_all_lists_when_deleting_completed":"Eliminar de todas las listas del grupo de listas cuando se eliminen elementos completados",
        "days_conflict_log_to_view":"Días de registro de conflictos para ver:",
        "language": "Idioma",
        "remove": "Eliminar",
        "loading_recipes": "Cargando Recetas...",
        "recipes": "Recetas",
        "loading_recipe": "Cargando Receta...",
        "delete_this_recipe": "Eliminar Receta",
        "really_delete_recipe": "¿Estás seguro de que quieres eliminar esta receta?",
        "editing_recipe": "Editando Receta:",
        "items_in_recipe": "Ingredientes en la Receta",
        "add_question": "¿Agregar?",
        "item": "Ingrediente",
        "recipe_steps": "Pasos de la Receta",
        "add_items_to": "Agregar Ingredientes a",
        "loading_recipe_import": "Cargando Página de Importación de Recetas...",
        "import_new": "Importar Nuevo",
        "importing_recipe": "Importando Receta",
        "import_file": "Archivo de Importación",
        "recipe_import_type": "Tipo de Archivo de Importación de Recetas",
        "recipe_import_type_tandoor": "Tandoor",
        "recipe_import_type_json_ld": "JSON-LD",
        "loading_uoms": "Cargando Unidades de Medida...",
        "uoms":"Unidades de Medida",
        "loading_uom": "Cargando Unidad de Medida...",
        "items_using_uom_one": "Hay {{count}} ingrediente usando esta UDM.",
        "items_using_uom_other": "Hay {{count}} ingredientes usando esta UDM",
        "recipes_using_uom_one": "Hay {{count}} receta usando esta UDM.",
        "recipes_using_uom_other": "Hay {{count}} recetas usando esta UDM.",
        "delete_this_uom": "¿Eliminar esta Unidad de Medida: {{uom}} ?",
        "really_delete_uom": "Esta UDM será eliminada de todos los ingredientes y recetas. ¿Estás seguro de que quieres eliminarla?",
        "all_uom_info_lost": "Se perderá toda la información de la unidad de medida",
        "editing_uom": "Editando UDM",
        "alternate_abbreviations": "Abreviaciones Alternas de la UDM",
        "uom_code": "Código (ISO)",
        "add_recipe_to_list": "Agregar Receta a la Lista",
        "add_items_in_recipe": "Continuar para agregar los ingredientes de la receta {{recipeName}} a la lista",
        "adding_recipe_to_list": "Agregando Receta a la Lista...",
        "status_adding_recipe":"Estado de Agregando Receta a la Lista:",
        "updated_item_successfully": "Ingrediente {{name}} actualizado exitosamente",
        "added_item_successfully": "Ingrediente {{name}} agregado exitosamente",
        "importing_recipes": "Importando Recetas...",
        "import_recipe_results": "Resultados de la Importación de Recetas",
        "processing_zip_file": "Procesando Archivo Zip",
        "import_recipes_q": "¿Importar Recetas?",
        "select_recipes_import": "Selecciona las recetas a importar abajo",
        "loaded_recipe_successfully":"Receta {{name}} cargada exitosamente.",
        "plural_name": "Nombre Plural",
        "description": "Descripción",        
        "recipe_items_added_to_list":"Elementos de la receta añadidos a la lista",
        "item_on_recipe":"Artículo en la receta:",
        "recipe_quantity":"Cantidad de la receta",
        "recipe_uom": "Unidad de medida de la receta",
        "shopping_quantity":"Cantidad de compra",
        "shopping_uom": "Unidad de medida de la compra",
        "update_user_info": "Actualizar información de usuario",
        "include_globalitems_in_search": "Incluir elementos globales en la búsqueda",
        "offline_cant_get_sharing_info": "Trabajando sin conexión, no se pueden recuperar ni editar usuarios compartidos",
        "logged_in": "Conectado",
        "working_offline": "Trabajando sin conexión",
        "online": "En línea",
        "attempt_login_again": "Intentar iniciar sesión nuevamente",
        "must_stay_offline": "Permanecer sin conexión y la API/base de datos aún están sin conexión. Continúa trabajando sin conexión y los cambios se pondrán en cola.",
        "as_user": "como usuario",
        "api_server_status": "Servidor API:",
        "db_server_status": "Servidor de BD:",
        "refresh_token_valid": "Token de actualización:",
        "access_token_valid": "Token de acceso:",
        "expires_in": "Expira en:",
        "expired_by": "Expirado por:",
        "days_one": " día",
        "days_other": " días",
        "relogin": "volver a iniciar sesión",
        "confirm_remove_completed_items": "Confirme que desea eliminar elementos completados de la lista",
        "delete_this_category": "Eliminar esta categoría: {{category}}?",
        "really_delete_category": "¿Realmente desea eliminar esta categoría?",
        "all_category_info_lost": "Se perderá toda la información de esta categoría.",
        "deleting_category": "Eliminando categoría...",
        "deleting_list": "Eliminando lista...",
        "deleting_uom": "Eliminando unidad de medida...",
        "is_default_listgroup_for_user":"¿Es la lista de recetas un grupo para este usuario?",
        "alexa_default": "Grupo de lista predeterminado para Alexa",
        "listgroup_not_updated": "El grupo de lista no fue actualizado/guardado",
        "exit": "Salir de la aplicación"
         },
     "error": {
        "invalid_dbuuid" : "Identificador único de base de datos inválido",
        "could_not_contact_api_server": "No se pudo contactar al servidor API",
        "invalid_jwt_token": "Token JWT inválido",
        "db_server_not_available": "Servidor de base de datos no disponible",
        "no_existing_credentials_found": "No se encontraron credenciales existentes",
        "no_api_server_url_entered":"No se ha ingresado URL del servidor API",
        "no_couchdb_url_found":"No se encontró URL de CouchDB",
        "invalid_api_url": "URL del API inválida",
        "invalid_couchdb_url": "URL de CouchDB inválida",
        "no_database_name_found":"No se encontró el nombre de la base de datos",
        "no_database_username_entered": "No se ha ingresado el nombre de usuario de la base de datos",
        "username_6_chars_or_more": "Ingrese un nombre de usuario de 6 caracteres o más",
        "invalid_username_format":"Formato de nombre de usuario inválido",
        "invalid_fullname_format":"Formato de nombre completo inválido",
        "no_email_entered":"No se ha ingresado correo electrónico",
        "invalid_email_format": "Formato de correo electrónico inválido",
        "no_password_entered":"No se ha ingresado contraseña",
        "password_not_long_enough":"La contraseña es demasiado corta. Por favor ingrese una contraseña de 6 caracteres o más",
        "passwords_no_match":"Las contraseñas no coinciden",
        "could_not_find_items": "No se encontraron artículos",
        "loading_item_info_restart": "Error al cargar la información del artículo... Reiniciando.",
        "no_items_available": "No hay artículos disponibles",
        "loading_category_info": "Error al cargar la información de la categoría... Reiniciando.",
        "must_enter_a_name": "Debe ingresar un nombre",
        "duplicate_category_name": "Nombre de categoría duplicado",
        "updating_category": "Error al actualizar la categoría",
        "please_retry": "Por favor, inténtelo de nuevo",
        "unable_remove_category_items":"No se puede eliminar la categoría de los artículos",
        "unable_remove_category_lists":"No se puede eliminar la categoría de las listas",
        "unable_delete_category": "No se puede eliminar la categoría",
        "loading_conflict_info": "Error al cargar la información de conflicto... Reiniciando.",
        "loading_conflict_log": "Error al cargar el registro de conflictos... Reiniciar.",
        "no_items_in_conflict_log": "No hay elementos en el registro de conflictos",
        "error": "Error...",
        "error_in_application": "Error en la aplicación. Reiniciar.",
        "loading_friend_info": "Error al cargar la información de amigos... Reiniciar.",
        "confirming_friend": "Error al confirmar amigo. Por favor, inténtelo de nuevo.",
        "friend_already_exists": "Ya existe un amigo con este correo electrónico",
        "creating_friend": "Error al crear amigo. Por favor, inténtelo de nuevo.",
        "loading_global_items": "Error al cargar elementos globales... Reiniciar.",
        "loading_global_item": "Error al cargar información de elemento global... Reiniciar.",
        "no_global_items_available": "No hay elementos globales disponibles",
        "loading_list_info":"Error al cargar información de la lista... Reiniciar.",
        "cannot_use_name_existing_item": "No se puede usar el nombre de un elemento existente en el grupo de lista",
        "cannot_use_name_existing_globalitem": "No se puede usar el nombre de un elemento existente en la lista de elementos globales",
        "updating_item": "Error al actualizar el elemento. Por favor, inténtelo de nuevo.",
        "adding_category": "Error al agregar categoría. Por favor, inténtelo de nuevo.",
        "uom_exists": "La unidad de medida solicitada ya existe. Por favor, inténtelo de nuevo.",
        "uom_length_error": "Las unidades de medida deben tener 2 caracteres. Por favor, inténtelo de nuevo.",
        "no_uom_description": "No se ha ingresado ninguna descripción de la unidad de medida. Por favor, inténtelo de nuevo.",
        "uom_description_exists": "La descripción de la unidad de medida solicitada ya existe. Por favor, inténtelo de nuevo.",
        "no_uom_plural_description":"No se ha ingresado ninguna descripción plural de la unidad de medida. Por favor, inténtelo de nuevo.",
        "uom_plural_description_exists":"La descripción plural de la unidad de medida solicitada ya existe. Por favor, inténtelo de nuevo.",
        "adding_uom": "Error al agregar la unidad de medida. Por favor, inténtelo de nuevo.",
        "adding_to_list": "Error al agregar a la lista",
        "item_exists_current_list": "El elemento ya existe en la lista actual",
        "adding_item": "Error al agregar el elemento, por favor inténtelo de nuevo.",
        "checking_items_list_group_header": "Comprobando elementos en el grupo de lista",
        "checking_items_list_group_detail": "Advertencia: está marcando/desmarcando elementos mientras está en modo de grupo de lista. Normalmente cambiaría primero a la lista de compras para realizar estos cambios. ¿Continuar?",
        "updating_item_completed": "Error al actualizar el estado completado. Por favor, inténtelo de nuevo.",
        "deleting_items_list": "Error al eliminar elementos de la lista. Por favor, inténtelo de nuevo.",
        "please_create_list_before_adding_items": "Por favor, crea al menos una lista antes de agregar elementos",
        "no_items_on_list": "No hay elementos en la lista",
        "must_select_valid_listgroup_id": "Debe seleccionar un ID de grupo de lista válido",
        "creating_updating_list": "Error al crear/actualizar la lista. Por favor, inténtalo de nuevo.",
        "could_not_delete_list": "No se pudo eliminar la lista",
        "unable_remove_list_all_items": "No se puede eliminar la lista de todos los elementos",
        "loading_listgroup": "Error al cargar la información del grupo de lista... Reiniciando.",
        "creating_updating_listgroup": "Error al crear/actualizar el grupo de lista",
        "could_not_delete_listgroup": "No se pudo eliminar el grupo de lista",
        "unable_remove_listgroup_items": "No se puede eliminar el grupo de lista de todos los elementos",
        "delete_listgroup": "No se puede eliminar el grupo de lista",
        "delete_listgroup_detail": "No puedes eliminar el último grupo de lista restante donde eres el propietario.",
        "loading_listgroups": "Error al cargar la información de los grupos de lista... Reiniciando.",
        "app_not_support_newer_schema": "Esta aplicación no es compatible con una versión más reciente detectada del esquema. Por favor, actualice la aplicación e inténtelo de nuevo.",
        "app_not_support_newer_schema_short":"La aplicación no admite el esquema de la base de datos",
        "warning": "Advertencia",
        "different_database_schema": "El esquema de la base de datos en el servidor no es el mismo que en la copia local. Esto es normal cuando hay una actualización significativa de la aplicación en curso. Deberías eliminar tu copia local para continuar. La aplicación se cerrará.",
        "different_database_schema_short": "Diferentes esquemas de base de datos en local/remoto",
        "server_no_unique_id": "El servidor está configurado incorrectamente sin una ID única. Por favor, asegúrese de que el proceso del servidor esté en ejecución.",
        "server_no_unique_id_short":"El servidor no tiene una ID de base de datos única.",
        "different_database_unique_id": "El identificador de la base de datos en el servidor no es el mismo que en la copia local. Deberías eliminar tu copia local para continuar. La aplicación se cerrará.",
        "invalid_authentication": "Autenticación inválida",
        "creating_user": "Error al crear usuario",
        "invalid_data_entered": "Datos inválidos ingresados",
        "user_already_exists": "El usuario ya existe",
        "must_enter_username_reset_password": "Debe ingresar el nombre de usuario para restablecer la contraseña",
        "database_server_not_available_choose_work_offline": "El servidor de la base de datos no está disponible. Puede elegir trabajar sin conexión y sus cambios se sincronizarán cuando vuelva a iniciar la aplicación y el servidor esté disponible. El riesgo de conflictos aumenta al trabajar sin conexión.",
        "loading_recipes": "Error al cargar recetas... Reiniciar.",
        "no_recipes_exist": "Aún no existen recetas",
        "loading_recipe": "Error al cargar receta... Reiniciar.",
        "duplicate_recipe_name": "Ya existe una receta con este nombre.",
        "updating_recipe": "Error al actualizar receta.",
        "unable_delete_recipe": "No se pudo eliminar la receta, intentar de nuevo.",
        "loading_recipe_import": "Error al cargar la página de importación de recetas... Reiniciar.",
        "loading_uom_info": "Error al cargar la información de la unidad de medida... Reiniciar.",
        "loading_uom": "Error al cargar la unidad de medida... Reiniciar.",
        "duplicate_uom_name": "Ya existe una unidad de medida con este nombre (código corto o descripción)",
        "must_enter_description": "Debe ingresar una descripción",
        "must_enter_plural_description": "Debe ingresar una descripción plural",
        "unable_remove_uom_items": "No se pudo quitar la unidad de medida de los elementos",
        "unable_remove_uom_recipes": "No se pudo quitar la unidad de medida de las recetas",
        "unable_delete_uom": "No se pudo eliminar la unidad de medida",
        "blank_alternate_uom": "La unidad de medida alternativa no debe estar en blanco",
        "duplicate_alt_uom": "Duplicados en la lista de unidades de medida alternativas",
        "picking_import_file": "Error al seleccionar el archivo de importación de recetas",
        "no_import_file_selected": "No se seleccionó ningún archivo de importación de recetas",
        "recipe_item_not_selected_to_add": "El artículo {{recipeName}} no fue seleccionado para agregar.",
        "no_item_found_update_recipe":"No se encontró ningún elemento para actualizar {{itemName}}",
        "uom_mismatch_recipe_import_note":"ADVERTENCIA: discrepancia en la unidad de medida al importar receta. Cantidad/UOM de compra de la receta es {{quantity}} {{uom}}",
        "updating_item_x": "Error al actualizar el artículo {{name}}",
        "uom_mismatch_recipe_import_status":"ADVERTENCIA: discrepancia en la unidad de medida en {{name}} (UOM de compra es {{shoppingUom}}, la lista fue {{listUom}}) - por favor verifique.",
        "recipe_note_overwritten":"ADVERTENCIA: La nota en el elemento fue sobrescrita con la nota de la receta",
        "adding_item_x":"Error al agregar el artículo {{name}}",
        "invalid_zip_file": "Archivo Zip inválido",
        "zip_not_contain_recipe":"Al menos uno de los archivos zip no contenía una entrada de receta",
        "invalid_recipe_json":"JSON de receta inválido en el archivo",
        "nothing_to_load": "Nada que cargar",
        "could_not_import_recipe_dup":"No se pudo importar: {{recipe}} - Duplicado",
        "could_not_match_uom":"No se pudo encontrar la unidad de medida. Original era: {{name}} o {{pluralName}}",
        "must_enter_a_plural_name":"Debe ingresar un nombre plural",
        "account_creation_disabled":"creación de cuenta deshabilitada",
        "updating_uom": "Error al actualizar la unidad de medida",
        "database_server_not_available": "Servidor de base de datos no disponible",
        "press_button_work_offline_as_user": "Presiona el botón de abajo para trabajar sin conexión como usuario:",
        "server_not_available_nor_cached_creds": "El servidor no está disponible para iniciar sesión. No hay credenciales en caché (o las credenciales eran inválidas) para poder usar la aplicación localmente. Por favor, inicie sesión nuevamente cuando el servidor esté disponible.",
        "unknown_login_state": "Estado de inicio de sesión desconocido. Por favor, reinicie la aplicación.",
        "friends_server_unavailable": "No se pueden mostrar ni actualizar los datos de amigos. La API y/o la base de datos no están disponibles.",
        "app_already_running": "Ya se está ejecutando en otra sesión, por favor continúa usando la aplicación allí.",
        "listgroup_already_exists": "Ya existe un grupo de listas con este nombre.",
        "list_already_exists": "Ya existe una lista con este nombre.",
        "cannot_create_category_no_listgroup_selected": "No se puede crear una categoría sin seleccionar un grupo de listas.",
        "cannot_create_uom_no_listgroup_selected": "No se puede crear una unidad de medida sin seleccionar un grupo de listas.",
        "header_adding_item": "Error al agregar un elemento existente",
        "header_warning_adding_item": "Advertencia al agregar un elemento existente",
        "warning_none_set_active": "Según la configuración, aunque el elemento se ha agregado, no está activo actualmente en ninguna de las listas. Esto puede ser porque la categoría no está activa en las listas.",
        "deleting_listgroup": "Error al eliminar el grupo de lista",
        "deleting_listgroup_detail": "No se puede eliminar el último grupo de lista restante. Por favor, agregue otro primero si realmente desea eliminarlo.",
        "need_one_alexa_default": "Debe haber un grupo de lista marcado como predeterminado para Alexa. Actualmente hay {{count}} grupos de listas marcados como predeterminados si guarda este grupo de lista. Si no se especifica ninguno, Alexa utilizará el primer grupo de lista disponible. Si se especifican más de uno, se utilizará el primero en orden alfabético.",
        "different_server_local_app_versions": "Esta versión de la aplicación es diferente a la del servidor. Podrías encontrar errores si continúas y debes actualizar el cliente o el servidor para que estén sincronizados antes de continuar.",
        "different_server_local_app_versions_short": "La versión de la aplicación es diferente en el servidor remoto"
    },
    "itemtext": {
        "item_is_on_these_lists": "El artículo está en estas listas:",
        "highlighted_lists_diff_values": "Las listas resaltadas tienen valores diferentes a nivel de artículo-lista",
        "list_values": "Valores de lista",
        "item_was_purchased_from_here": "El artículo fue comprado desde aquí"
     },
     "category": {
        "bakery": "Panadería",
        "deli": "Charcutería",
        "dairy": "Productos Lácteos",
        "baking": "Horneado",
        "cannedmeat": "Carne enlatada",
        "chips": "Snacks-Papas fritas",
        "pretzels": "Snacks-Pretzels",
        "cookies": "Snacks-Galletas",
        "crackers": "Snacks-Galletas saladas",
        "nuts": "Snacks-Nueces",
        "cannedvegetables": "Vegetales enlatados",
        "cannedsoup": "Sopas enlatadas",
        "paperproducts": "Productos de Papel",
        "juice": "Jugo",
        "soda": "Refresco",
        "pharmacy": "Farmacia",
        "beverages": "Bebidas",
        "frozentreats": "Postres Helados",
        "frozendinners": "Cenas Congeladas",
        "frozenvegetables": "Vegetales Congelados",
        "frozenother": "Otros Congelados",
        "laundry": "Lavandería",
        "meat": "Carne",
        "seafood": "Mariscos",
        "produce": "Productos Agrícolas",
        "condiments": "Condimentos",
        "mexican": "Comida Mexicana",
        "asian": "Comida Asiática",
        "italian": "Comida Italiana",
        "cereal": "Cereales",
        "bread" : "Pan",
        "alcohol" : "Bebidas Alcohólicas",
        "floral": "Flores",
        "petfood": "Comida para Mascotas",
        "cleaning": "Limpieza",
        "hair": "Productos para el Cabello-Shampoo/Acondicionador",
        "auto": "Automotriz",
        "office": "Suministros de Oficina",
        "beauty": "Productos de Belleza",
        "breakfast": "Desayuno",
        "coffeetea": "Café/Té",
    },
    "uom": {
        "EA_one": "Cada uno",
        "EA_other": "Cada uno",
        "X2_one": "Racimo",
        "X2_other": "Racimos",
        "OZ_one": "Onza",
        "OZ_other": "Onzas",
        "FO_one": "Onza líquida",
        "FO_other": "Onzas líquidas",
        "LB_one": "Libra",
        "LB_other": "Libras",
        "GA_one": "Galón",
        "GA_other": "Galones",
        "GH_one": "Media galón",
        "GH_other": "Medias galones",
        "QT_one": "Cuarto",
        "QT_other": "Cuartos",
        "LT_one": "Litro",
        "LT_other": "Litros",
        "ML_one": "Mililitro",
        "ML_other": "Mililitros",
        "KG_one": "Kilogramo",
        "KG_other": "Kilogramos",
        "GR_one": "Gramo",
        "GR_other": "Gramos",
        "BX_one": "Caja",
        "BX_other": "Cajas",
        "BG_one": "Bolsa",
        "BG_other": "Bolsas",
        "BO_one": "Botella",
        "BO_other": "Botellas",
        "CA_one": "Caja",
        "CA_other": "Cajas",
        "CN_one": "Lata",
        "CN_other": "Latas",
        "CU_one": "Taza",
        "CU_other": "Tazas",
        "CT_one": "Cartón",
        "CT_other": "Cartones",
        "CH_one": "Contenedor",
        "CH_other": "Contenedores",
        "DZ_one": "Docena",
        "DZ_other": "Docenas",
        "JR_one": "Frasco",
        "JR_other": "Frascos",
        "X8_one": "Pan",
        "X8_other": "Panes",
        "Y1_one": "Rebanada",
        "Y1_other": "Rebanadas",
        "15_one": "Barra",
        "15_other": "Barras",
        "PC_one": "Pieza",
        "PC_other": "Piezas",
        "PK_one": "Paquete",
        "PK_other": "Paquetes",
        "PT_one": "Pinta",
        "PT_other": "Pintas",
        "RL_one": "Rollo",
        "RL_other": "Rollos",
        "Z-CL_one": "Diente de ajo",
        "Z-CL_other": "Dientes de ajo",
        "Y2_one": "Cucharada",
        "Y2_other": "Cucharadas",
        "Y3_one": "Cucharadita",
        "Y3_other": "Cucharaditas"
},
    "globalitem":
    {
        "bananas_one": "Plátanos",
        "bananas_other": "Plátanos",
        "cosmiccrisp_one": "Manzanas Cosmic Crisp",
        "cosmiccrisp_other": "Manzanas Cosmic Crisp",
        "blueberries_one": "Arándanos Azules",
        "blueberries_other": "Arándanos Azules",
        "blackberries_one": "Moras Negras",
        "blackberries_other": "Moras Negras",
        "strawberries_one": "Fresas",
        "strawberries_other": "Fresas",
        "raspberries_one": "Frutillas",
        "raspberries_other": "Frutillas",
        "apples_one": "Manzanas",
        "apples_other": "Manzanas",
        "redpeppers_one": "Pimientos Rojos",
        "redpeppers_other": "Pimientos Rojos",
        "greenpeppers_one": "Pimientos Verdes",
        "greenpeppers_other": "Pimientos Verdes",
        "orangepeppers_one": "Pimientos Naranjas",
        "orangepeppers_other": "Pimientos Naranjas",
        "potatoes_one": "Papas",
        "potatoes_other": "Papas",
        "sweetpotatoes_one": "Batatas",
        "sweetpotatoes_other": "Batatas",
        "babyspinach_one": "Espinaca Bebé",
        "babyspinach_other": "Espinaca Bebé",
        "iceberglettuce_one": "Lechuga Iceberg",
        "iceberglettuce_other": "Lechuga Iceberg",
        "mixedgreens_one": "Mezcla de Lechugas",
        "mixedgreens_other": "Mezcla de Lechugas",
        "redseedlessgrapes_one": "Uvas Rojas sin Semillas",
        "redseedlessgrapes_other": "Uvas Rojas sin Semillas",
        "greenseedlessgrapes_one": "Uvas Verdes sin Semillas",
        "greenseedlessgrapes_other": "Uvas Verdes sin Semillas",
        "avocado_one": "Aguacate",
        "avocado_other": "Aguacate",
        "mandarins_one": "Mandarinas",
        "mandarins_other": "Mandarinas",
        "tangerines_one": "Mandarinas",
        "tangerines_other": "Mandarinas",
        "orangesseedless_one": "Naranjas sin Semillas",
        "orangesseedless_other": "Naranjas sin Semillas",
        "lemon_one": "Limón",
        "lemon_other": "Limón",
        "lime_one": "Lima",
        "lime_other": "Lima",
        "canteloupe_one": "Melón Cantalupo",
        "canteloupe_other": "Melón Cantalupo",
        "honeydewmelon_one": "Melón Honeydew",
        "honeydewmelon_other": "Melón Honeydew",
        "watermelon_one": "Sandía",
        "watermelon_other": "Sandía",
        "seedlesswatermelon_one": "Sandía sin Semillas",
        "seedlesswatermelon_other": "Sandía sin Semillas",
        "cherries_one": "Cerezas",
        "cherries_other": "Cerezas",
        "pineapple_one": "Piña",
        "pineapple_other": "Piña",
        "mango_one": "Mango",
        "mango_other": "Mango",
        "kiwi_one": "Kiwi",
        "kiwi_other": "Kiwi",
        "pear_one": "Pera",
        "pear_other": "Pera",
        "peaches_one": "Duraznos",
        "peaches_other": "Duraznos",
        "plum_one": "Ciruela",
        "plum_other": "Ciruela",
        "onion_one": "Cebolla",
        "onion_other": "Cebolla",
        "celery_one": "Apio",
        "celery_other": "Apio",
        "babycarrots_one": "Zanahorias Bebés",
        "babycarrots_other": "Zanahorias Bebés",
        "tomatoes_one": "Tomates",
        "tomatoes_other": "Tomates",
        "grapetomatoes_one": "Tomates Uva",
        "grapetomatoes_other": "Tomates Uva",
        "cucumber_one": "Pepino",
        "cucumber_other": "Pepino",
        "carrots_one": "Zanahorias",
        "carrots_other": "Zanahorias",
        "broccoli_one": "Brócoli",
        "broccoli_other": "Brócoli",
        "mushrooms_one": "Hongos",
        "mushrooms_other": "Hongos",
        "asparagus_one": "Espárragos",
        "asparagus_other": "Espárragos",
        "garlic_one": "Ajo",
        "garlic_other": "Ajo",
        "cilantro_one": "Cilantro",
        "cilantro_other": "Cilantro",
        "corn_one": "Maíz",
        "corn_other": "Maíz",
        "cauliflower_one": "Coliflor",
        "cauliflower_other": "Coliflor",
        "jalapenopepper_one": "Pimiento Jalapeño",
        "jalapenopepper_other": "Pimiento Jalapeño",
        "brusselsprouts_one": "Col de Bruselas",
        "brusselsprouts_other": "Col de Bruselas",
        "parsley_one": "Perejil",
        "parsley_other": "Perejil",
        "ginger_one": "Jengibre",
        "ginger_other": "Jengibre",
        "greenbeans_one": "Ejotes",
        "greenbeans_other": "Ejotes",
        "squash_one": "Calabaza",
        "squash_other": "Calabaza",
        "zucchini_one": "Calabacín",
        "zucchini_other": "Calabacín",
        "kale_one": "Kale",
        "kale_other": "Kale",
        "basil_one": "Albahaca",
        "basil_other": "Albahaca",
        "radishes_one": "Rábanos",
        "radishes_other": "Rábanos",
        "cabbage_one": "Repollo",
        "cabbage_other": "Repollo",
        "eggplant_one": "Berenjena",
        "eggplant_other": "Berenjena",
        "spinach_one": "Espinaca",
        "spinach_other": "Espinaca",
        "beets_one": "Remolacha",
        "beets_other": "Remolacha",
        "serranopeppers_one": "Pimientos Serrano",
        "serranopeppers_other": "Pimientos Serrano",
        "romainelettuce_one": "Lechuga Romana",
        "romainelettuce_other": "Lechuga Romana",
        "coleslaw_one": "Ensalada de Col",
        "coleslaw_other": "Ensalada de Col",
        "shreddedlettuce_one": "Lechuga Rallada",
        "shreddedlettuce_other": "Lechuga Rallada",
        "salami_one": "Salami",
        "salami_other": "Salami",
        "pepperoni_one": "Pepperoni",
        "pepperoni_other": "Pepperoni",
        "deliham_one": "Jamón de Deli",
        "deliham_other": "Jamón de Deli",
        "slicedswisscheese_one": "Queso Suizo Rebanado",
        "slicedswisscheese_other": "Queso Suizo Rebanado",
        "bologna_one": "Bologna",
        "bologna_other": "Bologna",
        "slicedturkey_one": "Pavo Rebanado",
        "slicedturkey_other": "Pavo Rebanado",
        "slicedprovolone_one": "Queso Provolone Rebanado",
        "slicedprovolone_other": "Queso Provolone Rebanado",
        "rotisseriechicken_one": "Pollo a la Parrilla",
        "rotisseriechicken_other": "Pollo a la Parrilla",
        "wholemilk_one": "Leche Entera",
        "wholemilk_other": "Leche Entera",
        "1percentmilk_one": "Leche 1%",
        "1percentmilk_other": "Leche 1%",
        "2percentmilk_one": "Leche 2%",
        "2percentmilk_other": "Leche 2%",
        "skimmilk_one": "Leche Descremada",
        "skimmilk_other": "Leche Descremada",
        "orangejuice_one": "Jugo de Naranja",
        "orangejuice_other": "Jugo de Naranja",
        "freshlemonade_one": "Limonada Fresca",
        "freshlemonade_other": "Limonada Fresca",
        "butter_one": "Mantequilla",
        "butter_other": "Mantequilla",
        "eggs_one": "Huevos",
        "eggs_other": "Huevos",
        "sourcream_one": "Crema Agria",
        "sourcream_other": "Crema Agria",
        "cottagecheese_one": "Queso Cottage",
        "cottagecheese_other": "Queso Cottage",
        "whippedcream_one": "Crema Batida",
        "whippedcream_other": "Crema Batida",
        "whitebread_one": "Pan Blanco",
        "whitebread_other": "Pan Blanco",
        "wheatbread_one": "Pan Integral",
        "wheatbread_other": "Pan Integral",
        "cinnamonraisinbread_one": "Pan de Canela y Pasas",
        "cinnamonraisinbread_other": "Pan de Canela y Pasas",
        "englishmuffins_one": "Panecillos Ingleses Naturales",
        "englishmuffins_other": "Panecillos Ingleses Naturales",
        "englishmuffinscinnamonraisin_one": "Panecillos Ingleses de Canela y Pasas",
        "englishmuffinscinnamonraisin_other": "Panecillos Ingleses de Canela y Pasas",
        "frenchbread_one": "Pan Francés",
        "frenchbread_other": "Pan Francés",
        "italianbread_one": "Pan Italiano",
        "italianbread_other": "Pan Italiano",
        "blueberrymuffins_one": "Muffins de Arándanos",
        "blueberrymuffins_other": "Muffins de Arándanos",
        "cinnamonmuffins_one": "Muffins de Canela",
        "cinnamonmuffins_other": "Muffins de Canela",
        "chocolatechipmuffins_one": "Muffins de Chispas de Chocolate",
        "chocolatechipmuffins_other": "Muffins de Chispas de Chocolate",
        "groundbeef_one": "Carne Molida",
        "groundbeef_other": "Carne Molida",
        "filetmignon_one": "Filete Mignon",
        "filetmignon_other": "Filete Mignon",
        "stripsteak_one": "Filete de tira",
        "stripsteak_other": "Filete de tira",
        "chickenbreasts_one": "Pechugas de pollo",
        "chickenbreasts_other": "Pechugas de pollo",
        "chickendrumsticks_one": "Muslos de pollo",
        "chickendrumsticks_other": "Muslos de pollo",
        "italiansausage_one": "Salchicha italiana",
        "italiansausage_other": "Salchicha italiana",
        "salmon_one": "Salmón",
        "salmon_other": "Salmón",
        "freshshrimp_one": "Camarones frescos",
        "freshshrimp_other": "Camarones frescos",
        "chips_one": "Papas fritas",
        "chips_other": "Papas fritas",
        "bbqchips_one": "Papas fritas BBQ",
        "bbqchips_other": "Papas fritas BBQ",
        "sourcreamonionchips_one": "Papas fritas con crema agria y cebolla",
        "sourcreamonionchips_other": "Papas fritas con crema agria y cebolla",
        "tortillachips_one": "Tortillas de maíz fritas",
        "tortillachips_other": "Tortillas de maíz fritas",
        "pretzels_one": "Pretzels",
        "pretzels_other": "Pretzels",
        "minipretzels_one": "Mini pretzels",
        "minipretzels_other": "Mini pretzels",
        "saltines_one": "Galletas soda",
        "saltines_other": "Galletas soda",
        "almonds_one": "Almendras",
        "almonds_other": "Almendras",
        "peanuts_one": "Maníes",
        "peanuts_other": "Maníes",
        "honeyroastedpeanuts_one": "Maníes tostados con miel",
        "honeyroastedpeanuts_other": "Maníes tostados con miel",
        "mixednuts_one": "Nueces mixtas",
        "mixednuts_other": "Nueces mixtas",
        "walnuts_one": "Nueces",
        "walnuts_other": "Nueces",
        "pistachios_one": "Pistachos",
        "pistachios_other": "Pistachos",
        "cannedcorn_one": "Maíz enlatado",
        "cannedcorn_other": "Maíz enlatado",
        "cannedcreamcorn_one": "Crema de maíz enlatada",
        "cannedcreamcorn_other": "Crema de maíz enlatada",
        "cannedgreenbeans_one": "Judías verdes enlatadas",
        "cannedgreenbeans_other": "Judías verdes enlatadas",
        "cannedpeas_one": "Arvejas enlatadas",
        "cannedpeas_other": "Arvejas enlatadas",
        "cannedmushroomssliced_one": "Hongos enlatados (rebanados)",
        "cannedmushroomssliced_other": "Hongos enlatados (rebanados)",
        "cannedmushrooms_one": "Hongos enlatados",
        "cannedmushrooms_other": "Hongos enlatados",
        "cannedtomatosauce_one": "Salsa de tomate enlatada",
        "cannedtomatosauce_other": "Salsa de tomate enlatada",
        "cannedtomatopaste_one": "Puré de tomate enlatado",
        "cannedtomatopaste_other": "Puré de tomate enlatado",
        "cannedpetitedicedtomatoes_one": "Tomates cortados en cubitos pequeños enlatados",
        "cannedpetitedicedtomatoes_other": "Tomates cortados en cubitos pequeños enlatados",
        "tomatosoup_one": "Sopa de tomate",
        "tomatosoup_other": "Sopa de tomate",
        "chickennoodlesoup_one": "Sopa de pollo con fideos",
        "chickennoodlesoup_other": "Sopa de pollo con fideos",
        "creamofmushroomsoup_one": "Sopa crema de champiñones",
        "creamofmushroomsoup_other": "Sopa crema de champiñones",
        "creamofchickensoup_one": "Sopa crema de pollo",
        "creamofchickensoup_other": "Sopa crema de pollo",
        "vegetablesoup_one": "Sopa de verduras",
        "vegetablesoup_other": "Sopa de verduras",
        "bluecheesedressing_one": "Aderezo de Ensalada de Queso Azul",
        "bluecheesedressing_other": "Aderezo de Ensalada de Queso Azul",
        "ranchdressing_one": "Aderezo de Ensalada Ranch",
        "ranchdressing_other": "Aderezo de Ensalada Ranch",
        "balsalmicvinaigrettedressing_one": "Aderezo de Ensalada de Vinagre Balsámico",
        "balsalmicvinaigrettedressing_other": "Aderezo de Ensalada de Vinagre Balsámico",
        "worcestershiresauce_one": "Salsa Worcestershire",
        "worcestershiresauce_other": "Salsa Worcestershire",
        "soysauce_one": "Salsa de Soja",
        "soysauce_other": "Salsa de Soja",
        "applejuice_one": "Jugo de Manzana",
        "applejuice_other": "Jugo de Manzana",
        "whitegrapejuice_one": "Jugo de Uva Blanca",
        "whitegrapejuice_other": "Jugo de Uva Blanca",
        "grapejuice_one": "Jugo de Uva",
        "grapejuice_other": "Jugo de Uva",
        "tomatojuice_one": "Jugo de Tomate",
        "tomatojuice_other": "Jugo de Tomate",
        "cranberryjuice_one": "Jugo de Arándano",
        "cranberryjuice_other": "Jugo de Arándano",
        "coke_one": "Coca Cola",
        "coke_other": "Coca Cola",
        "dietcoke_one": "Coca Cola Light",
        "dietcoke_other": "Coca Cola Light",
        "pepsi_one": "Pepsi",
        "pepsi_other": "Pepsi",
        "dietpepsi_one": "Pepsi Light",
        "dietpepsi_other": "Pepsi Light",
        "bottledwater_one": "Agua Embotellada",
        "bottledwater_other": "Agua Embotellada",
        "seltzerwater_one": "Agua con Gas",
        "seltzerwater_other": "Agua con Gas",
        "sparklingwater_one": "Agua Mineral con Gas",
        "sparklingwater_other": "Agua Mineral con Gas",
        "vanillaicecream_one": "Helado de Vainilla",
        "vanillaicecream_other": "Helado de Vainilla",
        "chocolateicecream_one": "Helado de Chocolate",
        "chocolateicecream_other": "Helado de Chocolate",
        "neopolitanicecream_one": "Helado Napolitano",
        "neopolitanicecream_other": "Helado Napolitano",
        "frozenspinach_one": "Espinacas Congeladas",
        "frozenspinach_other": "Espinacas Congeladas",
        "frozenbroccoli_one": "Brócoli Congelado",
        "frozenbroccoli_other": "Brócoli Congelado",
        "frozenpetitebroccoliflorets_one": "Floretes de Brócoli Congelados",
        "frozenpetitebroccoliflorets_other": "Floretes de Brócoli Congelados",
        "frozencorn_one": "Maíz Congelado",
        "frozencorn_other": "Maíz Congelado",
        "frozengreenbeans_one": "Judías Verdes Congeladas",
        "frozengreenbeans_other": "Judías Verdes Congeladas",
        "frozenpeas_one": "Guisantes Congelados",
        "frozenpeas_other": "Guisantes Congelados",
        "frozenstrawberries_one": "Fresas Congeladas",
        "frozenstrawberries_other": "Fresas Congeladas",
        "frozenblueberries_one": "Arándanos Congelados",
        "frozenblueberries_other": "Arándanos Congelados",
        "frozenwaffles_one": "Waffles Congelados",
        "frozenwaffles_other": "Waffles Congelados",
        "frozenpancakes_one": "Panqueques Congelados",
        "frozenpancakes_other": "Panqueques Congelados",
        "frozentatertots_one": "Tater Tots Congelados",
        "frozentatertots_other": "Tater Tots Congelados",
        "frozenfrenchfries_one": "Papas Fritas Congeladas",
        "frozenfrenchfries_other": "Papas Fritas Congeladas",
        "pierogies_one": "Pierogies",
        "pierogies_other": "Pierogies",
        "ketchup_one": "Salsa de Tomate (Ketchup)",
        "ketchup_other": "Salsa de Tomate (Ketchup)",
        "bbqsauce_one": "Salsa BBQ",
        "bbqsauce_other": "Salsa BBQ",
        "blackolives_one": "Aceitunas Negras",
        "blackolives_other": "Aceitunas Negras",
        "vegetableoil_one": "Aceite Vegetal",
        "vegetableoil_other": "Aceite Vegetal",
        "oliveoil_one": "Aceite de Oliva",
        "oliveoil_other": "Aceite de Oliva",
        "semisweetchocchips_one": "Chispas de Chocolate Semi-Dulce",
        "semisweetchocchips_other": "Chispas de Chocolate Semi-Dulce",
        "darkchocchips_one": "Chispas de Chocolate Oscuro",
        "darkchocchips_other": "Chispas de Chocolate Oscuro",
        "marshmallows_one": "Malvaviscos",
        "marshmallows_other": "Malvaviscos",
        "cornbreadmix_one": "Mezcla para Pan de Maíz",
        "cornbreadmix_other": "Mezcla para Pan de Maíz",
        "flour_one": "Harina",
        "flour_other": "Harina",
        "chiaseeds_one": "Semillas de Chía",
        "chiaseeds_other": "Semillas de Chía",
        "flaxseed_one": "Semillas de Lino",
        "flaxseed_other": "Semillas de Lino",
        "bakingsoda_one": "Bicarbonato de Sodio",
        "bakingsoda_other": "Bicarbonato de Sodio",
        "bakingpowder_one": "Levadura en Polvo",
        "bakingpowder_other": "Levadura en Polvo",
        "wheatflour_one": "Harina de Trigo",
        "wheatflour_other": "Harina de Trigo",
        "sugar_one": "Azúcar",
        "sugar_other": "Azúcar",
        "confectionerssugar_one": "Azúcar en Polvo",
        "confectionerssugar_other": "Azúcar en Polvo",
        "cinnamon_one": "Canela",
        "cinnamon_other": "Canela",
        "salt_one": "Sal",
        "salt_other": "Sal",
        "groundpepper_one": "Pimienta Molida",
        "groundpepper_other": "Pimienta Molida",
        "chilipowder_one": "Chile en Polvo",
        "chilipowder_other": "Chile en Polvo",
        "basildried_one": "Albahaca Seca",
        "basildried_other": "Albahaca Seca",
        "oregano_one": "Orégano",
        "oregano_other": "Orégano",
        "nutmeg_one": "Nuez Moscada",
        "nutmeg_other": "Nuez Moscada",
        "groundginger_one": "Jengibre Molido",
        "groundginger_other": "Jengibre Molido",
        "spaghettisauce_one": "Salsa de Espagueti",
        "spaghettisauce_other": "Salsa de Espagueti",
        "pizzasauce_one": "Salsa de Pizza",
        "pizzasauce_other": "Salsa de Pizza",
        "spaghetti_one": "Espagueti",
        "spaghetti_other": "Espagueti",
        "pastashells_one": "Pasta - Conchas",
        "pastashells_other": "Pasta - Conchas",
        "pastabowtie_one": "Pasta - Moño",
        "pastabowtie_other": "Pasta - Moño",
        "pastarotini_one": "Pasta - Rotini",
        "pastarotini_other": "Pasta - Rotini",
        "pastatricolorrotini_one": "Pasta - Rotini Tricolor",
        "pastatricolorrotini_other": "Pasta - Rotini Tricolor",
        "cannedtunawater_one": "Atún enlatado en agua",
        "cannedtunawater_other": "Atún enlatado en agua",
        "cannedtunaoil_one": "Atún enlatado en aceite",
        "cannedtunaoil_other": "Atún enlatado en aceite",
        "cannedsalmon_one": "Salmón enlatado",
        "cannedsalmon_other": "Salmón enlatado",
        "salmonpouch_one": "Bolsa de salmón",
        "salmonpouch_other": "Bolsa de salmón",
        "dishwashingliquid_one": "Líquido lavavajillas",
        "dishwashingliquid_other": "Líquido lavavajillas",
        "dishwashingdetergent_one": "Detergente lavavajillas",
        "dishwashingdetergent_other": "Detergente lavavajillas",
        "sponges_one": "Esponjas",
        "sponges_other": "Esponjas",
        "toiletbowlcleaner_one": "Limpiador de inodoro",
        "toiletbowlcleaner_other": "Limpiador de inodoro",
        "handsoap_one": "Jabón de manos",
        "handsoap_other": "Jabón de manos",
        "bleachcleaner_one": "Limpiador con blanqueador",
        "bleachcleaner_other": "Limpiador con blanqueador",
        "bleach_one": "Blanqueador",
        "bleach_other": "Blanqueador",
        "laundrydetergent_one": "Detergente para ropa",
        "laundrydetergent_other": "Detergente para ropa",
        "fabricsoftener_one": "Suavizante de telas",
        "fabricsoftener_other": "Suavizante de telas",
        "colorbleach_one": "Blanqueador para colores",
        "colorbleach_other": "Blanqueador para colores",
        "laundrypods_one": "Cápsulas de detergente para ropa",
        "laundrypods_other": "Cápsulas de detergente para ropa",
        "disinfectingwipes_one": "Toallitas desinfectantes",
        "disinfectingwipes_other": "Toallitas desinfectantes",
        "steelwoolpads_one": "Almohadillas de lana de acero",
        "steelwoolpads_other": "Almohadillas de lana de acero",
        "drainclogremover_one": "Removedor de obstrucciones en tuberías",
        "drainclogremover_other": "Removedor de obstrucciones en tuberías",
        "bandaids_one": "Curitas",
        "bandaids_other": "Curitas",
        "cottonballs_one": "Bolas de algodón",
        "cottonballs_other": "Bolas de algodón",
        "nailpolishremover_one": "Removedor de esmalte de uñas",
        "nailpolishremover_other": "Removedor de esmalte de uñas",
        "notebook_one": "Cuaderno",
        "notebook_other": "Cuaderno",
        "scotchtape_one": "Cinta adhesiva Scotch",
        "scotchtape_other": "Cinta adhesiva Scotch",
        "maskingtape_one": "Cinta adhesiva de enmascarar",
        "maskingtape_other": "Cinta adhesiva de enmascarar",
        "packagingtape_one": "Cinta adhesiva para empaques",
        "packagingtape_other": "Cinta adhesiva para empaques",
        "copypaper_one": "Papel para copias",
        "copypaper_other": "Papel para copias",
        "superglue_one": "Pegamento rápido",
        "superglue_other": "Pegamento rápido",
        "glue_one": "Pegamento",
        "glue_other": "Pegamento",
        "crayons_one": "Crayones",
        "crayons_other": "Crayones",
        "pencils_one": "Lápices",
        "pencils_other": "Lápices",
        "pens_one": "Plumas",
        "pens_other": "Plumas",
        "envelopes_one": "Sobres",
        "envelopes_other": "Sobres",
        "paperclips_one": "Clips de Papel",
        "paperclips_other": "Clips de Papel",
        "rubberbands_one": "Bandas de Goma",
        "rubberbands_other": "Bandas de Goma",
        "scissors_one": "Tijeras",
        "scissors_other": "Tijeras",
        "papertowels_one": "Toallas de Papel",
        "papertowels_other": "Toallas de Papel",
        "toiletpaper_one": "Papel Higiénico",
        "toiletpaper_other": "Papel Higiénico",
        "tacoshells_one": "Carcasas de Taco",
        "tacoshells_other": "Carcasas de Taco",
        "salsajar_one": "Salsa (Tarro)",
        "salsajar_other": "Salsa (Tarro)",
        "tacoseasoning_one": "Condimento de Taco",
        "tacoseasoning_other": "Condimento de Taco",
        "tortillas_one": "Tortillas",
        "tortillas_other": "Tortillas",
        "raisinbran_one": "Cereal de Pasas",
        "raisinbran_other": "Cereal de Pasas",
        "maplesyrup_one": "Jarabe de Arce",
        "maplesyrup_other": "Jarabe de Arce",
        "windshieldwiperfluid_one": "Líquido Limpiaparabrisas",
        "windshieldwiperfluid_other": "Líquido Limpiaparabrisas",
        "coffee_one": "Café",
        "coffee_other": "Café",
        "coffeecreamer_one": "Crema de café",
        "coffeecreamer_other": "Cremas de cafe",
        "tea_one": "Té",
        "tea_other": "Té",
        "clementines_one":"Clementina",
        "clementines_other":"Clementinas"
    }
}