export const en_translations = 
{
    "general": {
        "friends": "Friends",
        "conflict_log": "Conflict Log",
        "groceries_menu": "Specifically Clementines",
        "lists": "Lists",
        "create_new_list": "Create New List",
        "manage_all_listgroups": "Manage All Listgroups",
        "other_actions": "Actions",
        "manage_categories": "Manage Categories",
        "manage_all_items": "Manage All Items",
        "view_global_items": "View Global Items",
        "settings": "Settings",
        "edit": "Edit",
        "editing": "Editing",
        "active": "Active",
        "inactive": "Inactive",
        "completed": "Completed",
        "stocked_here": "Stocked Here",
        "category": "Category",
        "uncategorized": "Uncategorized",
        "quantity": "Quantity",
        "uom_abbrev": "UoM",  // Unit of Measure Abbreviation
        "no_uom": "No UoM", // No specified unit of measure
        "times": "times",
        "reset": "Reset",
        "note": "Note",
        "cancel": "Cancel",
        "save": "Save",
        "add": "Add",
        "delete": "Delete",
        "name": "Name",
        "color": "Color",
        "needs_confirmed": "Needs Confirmed",
        "requested": "Requested",
        "confirmed": "Confirmed",
        "needs_registering": "Needs Registering",
        "take_picture_for_item":"Take a picture for your item",
        "loading_all_items": "Loading All Items...",
        "all_items": "All Items",
        "loading_categories": "Loading Categories...",
        "categories": "Categories",
        "loading_category": "Loading Category...",
        "items_using_category_one": "There is {{count}} item using this category.",
        "items_using_category_other": "There are {{count}} items using this category.",
        "lists_using_category_one": "There is {{count}} list using this category.",
        "lists_using_category_other": "There are {{count}} lists using this category.",
        "delete_this_list": "Delete This List: {{list}} ?",
        "really_delete_list": "Do you really want to delete this list?",
        "really_delete_list_extended": "Do you really want to delete this list?  All information on this list will be lost.",
        "all_list_info_lost": "All information on this list will be lost.",
        "editing_category": "Editing Category: ",
        "loading_conflict_info": "Loading Conflict Information...",
        "conflict_item": "Conflict Item",
        "from": "from",
        "main_differences": "Main Differences",
        "winner": "Winner",
        "losers": "Losers",
        "return": "Return",
        "loading_conflict_log": "Loading Conflict Log...",
        "set_as_viewed": "Set As Viewed",
        "loading_friends": "Loading Friends...",
        "email_sent": "An email has been sent to {{email}} to confirm and create their account. The URL is here: {{url}} . This has also been copied to the clipboard.",
        "url": "URL",
        "confirm": "Confirm",
        "prompt_register_friend": "There is no user with email {{email}} currently registered. Do you want to ask them to register?",
        "adding_friend": "Adding a new Friend",
        "email_address_friend": "E-Mail address for friend to add",
        "user_not_found_send_registration": "User not found, send registration request?",
        "send_registration": "Send Registration",
        "url_registration_confirmation": "URL for Registration Confirmation",
        "ok": "OK",
        "loading_global_item":"Loading Global Item...",
        "undefined": "Undefined",
        "viewing_global_item": "Viewing Global Item",
        "new_placeholder": "<NEW>",
        "default_category": "Default Category",
        "default_uom": "Default Unit of Measure",
        "go_back": "Go Back",
        "loading_global_items": "Loading Global Items...",
        "global_items": "Global Items",
        "loading": "Loading...",
        "logging_in": "Logging In...",
        "loading_item": "Loading Item...",
        "add_new_category": "Add new category",
        "add_new_uom":"Add new Unit of Measure",
        "plural_description": "Plural Description",
        "delete_this_item": "Delete this item?",
        "really_delete_this_item":"Do you really want to delete this item?",
        "editing_item":"Editing Item:",
        "take_photo": "Take Photo",
        "delete_photo": "Delete Photo",
        "change_here_change_all_below":"Change values here to change on all lists below",
        "item_note": "Item Note",
        "list_group": "List Group",
        "loading_items": "Loading Items",
        "continue_ignore": "Continue/Ignore",
        "items_on": "Items On :",
        "search": "Search",
        "delete_completed_items": "REMOVE COMPLETED",
        "loading_list": "Loading List...",
        "changing_selected_list":"Changing Selected List",
        "list_updated_not_saved_still_change":"List has been updated and not saved. Do you still want to change lists?",
        "editing_list":"Editing List",
        "creating_new_list":"Creating New List",
        "loading_list_group":"Loading List Group...",
        "lists_assigned_to_group":"Lists assigned to this group:",
        "you_are_listgroup_owner": "You are the list group owner",
        "is_listgroup_owner":"is the list group owner",
        "listgroup_shared_with_users":"List group is shared with these other users:",
        "delete_this_listgroup":"Delete this list group: {{listgroup}} ?",
        "delete_this_listgroup_detail":"Do you really want to delete this list group?  All information on this list group will be lost (lists and items).",
        "changing_selected_listgroup":"Changing Selected List Group",
        "changing_selected_listgroup_detail":"List group has been updated and not saved. Do you still want to change list groups?",
        "editing_listgroup":"Editing List Group:",
        "creating_listgroup":"Creating new list group",
        "loading_listgroups": "Loading List Groups...",
        "listgroups": "List Groups",
        "all_lists": "All Lists",
        "delete_exit":"Delete/Exit",
        "cancel_exit":"Cancel/Exit",
        "request_password_reset":"Request password reset",
        "press_reset_receive_email":"Press reset to receive an email link to reset your password",
        "api_server_url":"API Server URL",
        "username": "Username",
        "password": "Password",
        "email_address":"E-Mail address",
        "email": "E-mail",
        "fullname": "Full Name",
        "confirm_password": "Confirm Password",
        "login": "Login",
        "reset_password": "Reset Password",
        "create_account": "Create Account",
        "create":"Create",
        "work_offline":"Work Offline",
        "login_page": "Login Page",
        "remove_saved_credentials":"Do you want to remove your saved credentials? This will cause the application to restart and allow you to sign in again if desired.",
        "want_remove_local_database":"Do you want to remove the local database? This will cause the application to restart and allow you to sign in again if desired. It will also re-sync all data from the server.",
        "app_info": "App Info",
        "app_version": "App Version",
        "database_schema_version": "Database Schema Version",
        "user_info":"User Info",
        "user_id": "User ID",
        "logout":"Logout",
        "delete_local_data":"Delete Local Data",
        "add_other_list_options":"Add To Other List Options",
        "add_same_group_auto":"Add in Same Group Automatically",
        "add_same_categories_auto":"Add with same categories automatically",
        "dont_add_auto":"Don't Add automatically",
        "other_settings":"Other Settings",
        "remove_items_all_lists_purchased":"Remove items from all lists in list group when marked as purchased/completed",
        "delete_all_lists_when_deleting_completed":"Delete From All lists in list group when deleting completed items",
        "days_conflict_log_to_view":"Days of conflict log to view:",
        "language": "Language",
        "remove": "Remove",
        "loading_recipes": "Loading Recipes...",
        "recipes": "Recipes",
        "loading_recipe": "Loading Recipe...",
        "delete_this_recipe": "Delete Recipe",
        "really_delete_recipe": "Are you sure you want to delete this recipe?",
        "editing_recipe": "Editing Recipe:",
        "items_in_recipe": "Items In Recipe",
        "add_question": "Add?",
        "item": "Item",
        "recipe_steps": "Recipe Steps",
        "add_items_to": "Add Items to",
        "loading_recipe_import": "Loading Recipe Import Page...",
        "import_new": "Import New",
        "importing_recipe": "Importing Recipe",
        "import_file": "Import File",
        "recipe_import_type": "Recipe Import File Type",
        "recipe_import_type_tandoor": "Tandoor",
        "recipe_import_type_json_ld": "JSON-LD",
        "loading_uoms": "Loading Units of Measure ...",
        "uoms":"Units of Measure",
        "loading_uom": "Loading Unit of Measure...",
        "items_using_uom_one": "There is {{count}} item using this UoM.",
        "items_using_uom_other": "There are {{count}} items using this UoM",
        "recipes_using_uom_one": "There is {{count}} recipe using this UoM.",
        "recipes_using_uom_other": "There are {{count}} recipes using this UoM.",
        "delete_this_uom": "Delete this Unit of Measure: {{uom}} ?",
        "really_delete_uom": "This UOM will be removed from all items and recipes. Are you sure you want to delete?",
        "all_uom_info_lost": "All unit of measure info will be lost",
        "editing_uom": "Editing UOM",
        "alternate_abbreviations": "Alternate UoM Abbreviations",
        "uom_code": "Code (ISO)",
        "add_recipe_to_list": "Add Recipe To List",
        "add_items_in_recipe": "Proceed to add items in recipe {{recipeName}} to the list",
        "adding_recipe_to_list": "Adding Recipe To List...",
        "status_adding_recipe":"Status of adding recipe to list:",
        "updated_item_successfully": "Updated item {{name}} successfully",
        "added_item_successfully": "Added item {{name}} successfully",
        "importing_recipes": "Importing Recipes...",
        "import_recipe_results": "Recipe Import Results",
        "processing_zip_file": "Processing Zip File",
        "import_recipes_q": "Import Recipes?",
        "select_recipes_import": "Select the recipes to import below",
        "loaded_recipe_successfully":"Loaded Recipe {{name}} successfully.",
        "plural_name": "Plural Name",
        "description": "Description",
        "recipe_items_added_to_list":"Recipe Items added to list",
        "item_on_recipe":"Item on Recipe:",
        "recipe_quantity":"Recipe Quantity",
        "recipe_uom": "Recipe UoM",
        "shopping_quantity":"Shopping Quantity",
        "shopping_uom": "Shopping UoM",
        "update_user_info": "Update User Info",
        "include_globalitems_in_search": "Include Global Items in Search",
        "offline_cant_get_sharing_info": "Working offline, cannot retrieve or edit shared users",
        "logged_in": "Logged In",
        "working_offline": "Working Offline",
        "online": "Online",
        "attempt_login_again": "Attempt Login Again",
        "must_stay_offline": "Working offline and API/database are still offline. Continue to work offline and changes will be queued.",
        "as_user": "as user",
        "api_server_status": "API Server:",
        "db_server_status": "DB Server:",
        "refresh_token_valid": "Refresh Token:",
        "access_token_valid": "Access Token:",
        "expires_in": "Expires In:",
        "expired_by": "Expired By:",
        "days_one": " day",
        "days_other": " days",
        "relogin": "Relogin",
        "confirm_remove_completed_items": "Confirm that you want to remove completed items from the list",
        "delete_this_category": "Delete This Category : {{category}} ?",
        "really_delete_category": "Do you really want to delete this category?",
        "all_category_info_lost": "All information on this category will be lost.",
        "deleting_category": "Deleting Category...",
        "deleting_list": "Deleting List...",
        "deleting_uom": "Deleting Unit of Measure...",
        "is_recipe_listgroup_for_user":"Is a recipe list group",
        "alexa_default": "Default list group for Alexa",
        "listgroup_not_updated": "List group was not updated/saved",
        "exit": "Exit App"
     },
     "error" : {
        "invalid_dbuuid" : "Invalid Database Unique Identifier",
        "could_not_contact_api_server": "Could not contact API server",
        "invalid_jwt_token": "Invalid JWT Token",
        "db_server_not_available": "DB Server Not Available",
        "no_existing_credentials_found": "No existing credentials found",
        "no_api_server_url_entered":"No API Server URL entered",
        "no_couchdb_url_found":"No CouchDB URL found",
        "invalid_api_url": "Invalid API URL",
        "invalid_couchdb_url": "Invalid CouchDB URL",
        "no_database_name_found":"No database name found",
        "no_database_username_entered": "No database user name entered",
        "username_6_chars_or_more": "Please enter username of 6 characters or more",
        "invalid_username_format":"Invalid username format",
        "invalid_fullname_format":"Invalid full name format",
        "no_email_entered":"No email entered",
        "invalid_email_format": "Invalid email format",
        "no_password_entered":"No password entered",
        "password_not_long_enough":"Password not long enough. Please have 6 character or longer password",
        "passwords_no_match":"Passwords do not match",
        "could_not_find_items": "Could not find items",
        "loading_item_info_restart": "Error Loading Item Information... Restart.",
        "no_items_available": "No Items Available",
        "loading_category_info": "Error Loading Category Information... Restart.",
        "must_enter_a_name": "Must Enter a Name",
        "duplicate_category_name": "Duplicate Category Name",
        "updating_category": "Error updating category",
        "please_retry": "Please Retry",
        "unable_remove_category_items":"Unable to remove category from items",
        "unable_remove_category_lists":"Unable to remove category from lists",
        "unable_delete_category": "Unable to delete category",
        "loading_conflict_info": "Error Loading Conflict Information... Restart.",
        "loading_conflict_log": "Error Loading Conflict Log... Restart.",
        "no_items_in_conflict_log": "No Items In Conflict Log",
        "error": "Error...",
        "error_in_application": "Error in Application. Restart.",
        "loading_friend_info": "Error Loading Friend Information... Restart.",
        "confirming_friend": "Error confirming friend. Please retry.",
        "friend_already_exists": "Friend already exists with this email",
        "creating_friend": "Error creating friend. Please retry.",
        "loading_global_items": "Error Loading Global Items... Restart.",
        "loading_global_item": "Error Loading Global Item Information... Restart.",
        "no_global_items_available": "No Global Items Available",
        "loading_list_info":"Error Loading List Information... Restart.",
        "cannot_use_name_existing_item": "Cannot use name of existing item in list group",
        "cannot_use_name_existing_globalitem": "Cannot use name of existing item in global item list",
        "updating_item": "Error updating item. Please retry.",
        "adding_category": "Error adding category. Please retry.",
        "uom_exists": "Requested UOM Already exists. Please retry.",
        "uom_length_error": "Units of measure must be 2 characters. Please retry.",
        "no_uom_description": "No UOM Description entered. Please retry.",
        "uom_description_exists": "Requested UOM Description Already exists. Please retry.",
        "no_uom_plural_description":"No UOM Plural description entered. Please retry.",
        "uom_plural_description_exists":"Requested UOM Plural Description Already exists. Please retry.",
        "adding_uom": "Error adding unit of measure. Please retry.",
        "adding_to_list": "Error adding to list",
        "item_exists_current_list": "Item already exists in the current list",
        "adding_item": "Error adding item, please retry.",
        "checking_items_list_group_header": "Checking Items in List Group",
        "checking_items_list_group_detail": "Warning: You are checking off/on items while in List Group mode. Normally you would change to the shopping list first to make these changes. Continue?",
        "updating_item_completed": "Error updating completed status. Please retry.",
        "deleting_items_list": "Error deleting items from list. Please retry.",
        "please_create_list_before_adding_items":"Please create at least one list before adding items",
        "no_items_on_list": "No Items On List",
        "must_select_valid_listgroup_id": "Must Select a Valid List Group ID",
        "creating_updating_list": "Error Creating/Updating List. Please retry.",
        "could_not_delete_list": "Could not delete list",
        "unable_remove_list_all_items":"Unable to remove list from all items",
        "loading_listgroup": "Error Loading List Group Information... Restart.",
        "creating_updating_listgroup":"Error Creating/Updating List Group",
        "could_not_delete_listgroup":"Could not delete list group",
        "unable_remove_listgroup_items":"Unable to remove list group from all items",
        "delete_listgroup": "Cannot delete list group",
        "delete_listgroup_detail": "You cannot delete the last remaining list group where you are owner.",
        "loading_listgroups": "Error Loading List Groups Information... Restart.",
        "app_not_support_newer_schema":"This application does not support a detected newer version of the schema. Please upgrade the app and try again.",
        "app_not_support_newer_schema_short":"App does not support database schema",
        "warning": "Warning",
        "different_database_schema":"The Database schema on the server is not the same as the local copy. This is normal when there is a significant application update happening. You should delete your local copy in order to continue. App will exit.",
        "different_database_schema_short": "Different database schemes on local/remote",
        "server_no_unique_id":"The server is incorrectly configured with no unique ID, or is not accessible from the client. Please ensure server process is running.",
        "server_no_unique_id_short":"The server has no unique database ID",
        "different_database_unique_id":"The Database identifier on the server is not the same as the local copy. You should delete your local copy in order to continue. App will exit.",
        "invalid_authentication": "Invalid Authentication",
        "creating_user":"Error creating user",
        "invalid_data_entered":"Invalid Data Entered",
        "user_already_exists": "User Already Exists",
        "must_enter_username_reset_password":"Must enter username to reset password",
        "database_server_not_available_choose_work_offline":"The database and/or API server is not available. You can choose to work offline and your changes will sync when you start the app again and the server is available. The risk for conflicts increases when working offline.",
        "loading_recipes": "Error Loading Recipes... Restart.",
        "no_recipes_exist": "No Recipes Exist Yet",
        "loading_recipe": "Error Loading Recipe... Restart.",
        "duplicate_recipe_name": "Recipe name already exists.",
        "updating_recipe": "Error updating recipe.",
        "unable_delete_recipe": "Unable to delete recipe, retry.",
        "loading_recipe_import": "Error Loading Recipe Import Page... Restart.",
        "loading_uom_info": "Error loading UoM info... Restart.",
        "loading_uom": "Error loading UOM... Restart.",
        "duplicate_uom_name": "Duplicate UOM exists (short code or descriptions)",
        "must_enter_description": "Must Enter a Desription",
        "must_enter_plural_description": "Must Enter a Plural Description",
        "unable_remove_uom_items": "Unable to remove UOM from Items",
        "unable_remove_uom_recipes": "Unable to remove UOM from Recipes",
        "unable_delete_uom": "Unable to delete UOM",
        "blank_alternate_uom": "UOM Alternate must not be blank",
        "duplicate_alt_uom": "Duplicates in UOM Alternate list",
        "picking_import_file": "Error Picking Recipe Import File",
        "no_import_file_selected": "No Recipe Import File Selected",
        "recipe_item_not_selected_to_add": "Item {{recipeName}} not selected to add.",
        "no_item_found_update_recipe":"No item found to update for {{itemName}}",
        "uom_mismatch_recipe_import_note":"WARNING: Unit of measure mismatch on recipe import. Recipe shopping quantity/UoM is {{quantity}} {{uom}}",
        "updating_item_x": "Error Updating Item {{name}}",
        "uom_mismatch_recipe_import_status":"WARNING: Unit of measure mismatch on {{name}} (shopping UoM is {{shoppingUom}} ,list was {{listUom}}) - please check.",
        "recipe_note_overwritten":"WARNING: Note on item overwritten with recipe note",
        "adding_item_x":"Error adding item {{name}}",
        "invalid_zip_file": "Invalid Zip File",
        "zip_not_contain_recipe":"At least one zip file did not contain recipe input",
        "invalid_recipe_json":"Invalid recipe JSON in file",
        "nothing_to_load": "Nothing To Load",
        "could_not_import_recipe_dup":"Could not import: {{recipe}} - Duplicate",
        "could_not_match_uom":"Could Not find UoM. Original was: {{name}} or {{pluralName}}",
        "must_enter_a_plural_name":"Must Enter a Plural Name. Defaulted to current name.",
        "account_creation_disabled":"Account Creation Disabled",
        "updating_uom": "Error updating UoM",
        "database_server_not_available": "Database Server Not Available",
        "press_button_work_offline_as_user": "Press the button below to work offline as user:",
        "server_not_available_nor_cached_creds": "The server is not available to login. There are no cached credentials (or the credentials were invalid) to be able to use the app locally either. Please login again when the server is available.",
        "unknown_login_state": "Unknown Login State. Please restart the application.",
        "friends_server_unavailable": "Cannot display or update Friends data. API and/or Database is unavailable.",
        "app_already_running":"Already running in another session, please continue using the app there.",
        "listgroup_already_exists": "There is already a list group with this name.",
        "list_already_exists": "There is already a list with this name.",
        "cannot_create_category_no_listgroup_selected": "Cannot create a category with no list group selected",
        "cannot_create_uom_no_listgroup_selected": "Cannot create a unit of measure with no list group selected",
        "header_adding_item": "Error Adding Existing Item",
        "header_warning_adding_item": "Warning Adding Existing Item",
        "warning_none_set_active": "Based on settings, although the item was added, it is not currently active on any of the lists. This could be because the category is not active on the lists.",
        "deleting_listgroup": "Error Deleting List Group",
        "deleting_listgroup_detail": "Cannot delete the last remaining list group. Please add another one first if you really wish to delete.",
        "need_one_alexa_default": "There should be one list group marked as the Alexa default. Currently there are {{count}} list groups marked as the default if you save this list group. If one is not specified, Alexa will use the first available list group. If more than one is specified, it will use the first one alphabetically.",
        "different_server_local_app_versions": "This application version is different than the one on the server. You could encounter errors if you continue and should upgrade the client or server to be in sync before proceeding.",
        "different_server_local_app_versions_short": "Application version different on remote server"
    },
    "itemtext": {
        "item_is_on_these_lists": "Item is on these lists:",
        "highlighted_lists_diff_values": "Highlighted lists have different values at the item-list level",
        "list_values": "List Values",
        "item_was_purchased_from_here": "Item was Purchased From Here"
    },
    "category": {
        "bakery": "Bakery",
        "deli": "Deli",
        "dairy": "Dairy",
        "baking": "Baking",
        "cannedmeat": "Canned Meat",
        "chips": "Snacks-Chips",
        "pretzels": "Snacks-Pretzels",
        "cookies": "Snacks-Cookies",
        "crackers": "Snacks-Crackers",
        "nuts": "Snacks-Nuts",
        "cannedvegetables": "Canned Vegetables",
        "cannedsoup": "Canned Soup",
        "paperproducts": "Paper Products",
        "juice": "Juice",
        "soda": "Soda",
        "pharmacy": "Pharmacy",
        "beverages": "Beverages",
        "frozentreats": "Frozen Treats/Ice Cream",
        "frozendinners": "Frozen Dinners",
        "frozenvegetables": "Frozen Vegetables",
        "frozenother": "Frozen Other",
        "laundry": "Laundry",
        "meat": "Meat",
        "seafood": "Seafood",
        "produce": "Produce",
        "condiments": "Condiments",
        "mexican": "Mexican",
        "asian": "Asian",
        "italian": "Italian",
        "cereal": "Cereal",
        "bread" : "Bread",
        "alcohol" : "Alcohol",
        "floral": "Floral",
        "petfood": "Pet Food",
        "cleaning": "Cleaning",
        "hair": "Hair Products-Shampoo/Conditioner",
        "auto": "Automotive",
        "office": "Office Supplies",
        "beauty": "Beauty Products",
        "breakfast": "Breakfast (other)",
        "coffeetea": "Coffee/Tea",
    },
    "uom": {
        "EA_one": "Each",
        "EA_other": "Eaches",
        "X2_one": "Bunch",
        "X2_other": "Bunches",
        "OZ_one": "Ounce",
        "OZ_other": "Ounces",
        "FO_one": "Fluid Ounce",
        "FO_other": "Fluid Ounces",
        "LB_one": "Pound",
        "LB_other": "Pounds",
        "GA_one": "Gallon",
        "GA_other": "Gallons",
        "GH_one": "Half Gallon",
        "GH_other": "Half Gallons",
        "QT_one": "Quart",
        "QT_other": "Quarts",
        "LT_one": "Liter",
        "LT_other": "Liters",
        "ML_one": "Milliliter",
        "ML_other": "Milliliters",
        "KG_one": "Kilogram",
        "KG_other": "Kilograms",
        "GR_one": "Gram",
        "GR_other": "Grams",
        "BX_one": "Box",
        "BX_other": "Boxes",
        "BG_one": "Bag",
        "BG_other": "Bags",
        "BO_one": "Bottle",
        "BO_other": "Bottles",
        "CA_one": "Case",
        "CA_other": "Cases",
        "CN_one": "Can",
        "CN_other": "Cans",
        "CU_one": "Cup",
        "CU_other": "Cups",
        "CT_one": "Carton",
        "CT_other": "Cartons",
        "CH_one": "Container",
        "CH_other": "Containers",
        "DZ_one": "Dozen",
        "DZ_other": "Dozen",
        "JR_one": "Jar",
        "JR_other": "Jars",
        "X8_one": "Loaf",
        "X8_other": "Loaves",
        "Y1_one": "Slice",
        "Y1_other": "Slices",
        "15_one": "Stick",
        "15_other": "Sticks",
        "PC_one": "Piece",
        "PC_other": "Pieces",
        "PK_one": "Package",
        "PK_other": "Packages",
        "PT_one": "Pint",
        "PT_other": "Pints",
        "RL_one": "Roll",
        "RL_other": "Rolls",
        "Z-CL_one": "Clove",
        "Z-CL_other": "Cloves",
        "Y2_one": "Tablespoon",
        "Y2_other": "Tablespoons",
        "Y3_one": "Teaspoon",
        "Y3_other": "Teaspoons"
    },
    "globalitem": 
    {
        "bananas_one": "Banana",
        "bananas_other": "Bananas",
        "cosmiccrisp_one": "Cosmic Crisp Apple",
        "cosmiccrisp_other": "Cosmic Crisp Apples",
        "blueberries_one": "Blueberry",
        "blueberries_other": "Blueberries",
        "blackberries_one": "Blackberry",
        "blackberries_other": "Blackberries",
        "strawberries_one": "Strawberry",
        "strawberries_other": "Strawberries",
        "raspberries_one": "Raspberry",
        "raspberries_other": "Raspberries",
        "apples_one": "Apple",
        "apples_other": "Apples",
        "redpeppers_one": "Red Pepper",
        "redpeppers_other": "Red Peppers",
        "greenpeppers_one": "Green Pepper",
        "greenpeppers_other": "Green Peppers",
        "orangepeppers_one": "Orange Pepper",
        "orangepeppers_other": "Orange Peppers",
        "potatoes_one": "Potato",
        "potatoes_other": "Potatoes",
        "sweetpotatoes_one": "Sweet Potato",
        "sweetpotatoes_other": "Sweet Potatoes",
        "babyspinach_one": "Baby Spinach",
        "babyspinach_other": "Baby Spinach",
        "iceberglettuce_one": "Iceberg Lettuce",
        "iceberglettuce_other": "Iceberg Lettuce",
        "mixedgreens_one": "Mixed Greens Lettuce",
        "mixedgreens_other": "Mixed Greens Lettuce",
        "redseedlessgrapes_one": "Red Seedless Grapes",
        "redseedlessgrapes_other": "Red Seedless Grapes",
        "greenseedlessgrapes_one": "Green Seedless Grapes",
        "greenseedlessgrapes_other": "Green Seedless Grapes",
        "avocado_one": "Avocado",
        "avocado_other": "Avocados",
        "mandarins_one": "Mandarin",
        "mandarins_other": "Mandarins",
        "tangerines_one": "Tangerine",
        "tangerines_other": "Tangerines",
        "orangesseedless_one": "Orange Seedless",
        "orangesseedless_other": "Oranges Seedless",
        "lemon_one": "Lemon",
        "lemon_other": "Lemons",
        "lime_one": "Lime",
        "lime_other": "Limes",
        "canteloupe_one": "Canteloupe",
        "canteloupe_other": "Canteloupes",
        "honeydewmelon_one": "Honeydew Melon",
        "honeydewmelon_other": "Honeydew Melons",
        "watermelon_one": "Watermelon",
        "watermelon_other": "Watermelons",
        "seedlesswatermelon_one": "Seedless Watermelon",
        "seedlesswatermelon_other": "Seedless Watermelons",
        "cherries_one": "Cherry",
        "cherries_other": "Cherries",
        "pineapple_one": "Pineapple",
        "pineapple_other": "Pineapples",
        "mango_one": "Mango",
        "mango_other": "Mangos",
        "kiwi_one": "Kiwi Fruit",
        "kiwi_other": "Kiwi Fruits",
        "pear_one": "Pear",
        "pear_other": "Pears",
        "peaches_one": "Peach",
        "peaches_other": "Peaches",
        "plum_one": "Plum",
        "plum_other": "Plums",
        "onion_one": "Onion",
        "onion_other": "Onions",
        "celery_one": "Celery",
        "celery_other": "Celery",
        "babycarrots_one": "Baby Carrot",
        "babycarrots_other": "Baby Carrots",
        "tomatoes_one": "Tomato",
        "tomatoes_other": "Tomatoes",
        "grapetomatoes_one": "Grape Tomato",
        "grapetomatoes_other": "Grape Tomatoes",
        "cucumber_one": "Cucumber",
        "cucumber_other": "Cucumbers",
        "carrots_one": "Carrot",
        "carrots_other": "Carrots",
        "broccoli_one": "Broccoli",
        "broccoli_other": "Broccoli",
        "mushrooms_one": "Mushroom",
        "mushrooms_other": "Mushrooms",
        "asparagus_one": "Asparagus",
        "asparagus_other": "Asparagus",
        "garlic_one": "Garlic",
        "garlic_other": "Garlic",
        "cilantro_one": "Cilantro",
        "cilantro_other": "Cilantro",
        "corn_one": "Corn",
        "corn_other": "Corn",
        "cauliflower_one": "Cauliflower",
        "cauliflower_other": "Cauliflower",
        "jalapenopepper_one": "Jalapeno Pepper",
        "jalapenopepper_other": "Jalapeno Peppers",
        "brusselsprouts_one": "Brussel Sprout",
        "brusselsprouts_other": "Brussel Sprouts",
        "parsley_one": "Parsley",
        "parsley_other": "Parsleys",
        "ginger_one": "Ginger",
        "ginger_other": "Ginger",
        "greenbeans_one": "Green Beans",
        "greenbeans_other": "Green Beans",
        "squash_one": "Squash",
        "squash_other": "Squash",
        "zucchini_one": "Zucchini",
        "zucchini_other": "Zucchinis",
        "kale_one": "Kale",
        "kale_other": "Kale",
        "basil_one": "Basil",
        "basil_other": "Basil",
        "radishes_one": "Radish",
        "radishes_other": "Radishes",
        "cabbage_one": "Cabbage",
        "cabbage_other": "Cabbages",
        "eggplant_one": "Eggplant",
        "eggplant_other": "Eggplants",
        "spinach_one": "Spinach",
        "spinach_other": "Spinach",
        "beets_one": "Beet",
        "beets_other": "Beets",
        "serranopeppers_one": "Serrano Pepper",
        "serranopeppers_other": "Serrano Peppers",
        "romainelettuce_one": "Romaine Lettuce",
        "romainelettuce_other": "Romaine Lettuces",
        "coleslaw_one": "Cole Slaw",
        "coleslaw_other": "Cole Slaw",
        "shreddedlettuce_one": "Shredded Lettuce",
        "shreddedlettuce_other": "Shredded Lettuce",
        "salami_one": "Salami",
        "salami_other": "Salami",
        "pepperoni_one": "Pepperoni",
        "pepperoni_other": "Pepperoni",
        "deliham_one": "Deli Ham",
        "deliham_other": "Deli Ham",
        "slicedswisscheese_one": "Sliced Swiss Cheese",
        "slicedswisscheese_other": "Sliced Swiss Cheese",
        "bologna_one": "Bologna",
        "bologna_other": "Bologna",
        "slicedturkey_one": "Sliced Turkey",
        "slicedturkey_other": "Sliced Turkey",
        "slicedprovolone_one": "Sliced Provolone",
        "slicedprovolone_other": "Sliced Provolone",
        "rotisseriechicken_one": "Rotisserie Chicken",
        "rotisseriechicken_other": "Rotisserie Chickens",
        "wholemilk_one": "Whole Milk",
        "wholemilk_other": "Whole Milk",
        "1percentmilk_one": "1% Milk",
        "1percentmilk_other": "1% Milk",
        "2percentmilk_one": "2% Milk",
        "2percentmilk_other": "2% Milk",
        "skimmilk_one": "Skim Milk",
        "skimmilk_other": "Skim Milk",
        "orangejuice_one": "Orange Juice",
        "orangejuice_other": "Orange Juices",
        "freshlemonade_one": "Fresh Lemonade",
        "freshlemonade_other": "Fresh Lemonades",
        "butter_one": "Butter",
        "butter_other": "Butter",
        "eggs_one": "Egg",
        "eggs_other": "Eggs",
        "sourcream_one": "Sour Cream",
        "sourcream_other": "Sour Creams",
        "cottagecheese_one": "Cottage Cheese",
        "cottagecheese_other": "Cottage Cheese",
        "whippedcream_one": "Whipped Cream",
        "whippedcream_other": "Whipped Cream",
        "whitebread_one": "White Bread",
        "whitebread_other": "White Breads",
        "wheatbread_one": "Wheat Bread",
        "wheatbread_other": "Wheat Breads",
        "cinnamonraisinbread_one": "Cinnamon Raisin Bread",
        "cinnamonraisinbread_other": "Cinnamon Raisin Breads",
        "englishmuffins_one": "English Muffins Plain",
        "englishmuffins_other": "English Muffins Plains",
        "englishmuffinscinnamonraisin_one": "English Muffins Cinnamon Raisin",
        "englishmuffinscinnamonraisin_other": "English Muffins Cinnamon Raisin",
        "frenchbread_one": "French Bread",
        "frenchbread_other": "French Breads",
        "italianbread_one": "Italian Bread",
        "italianbread_other": "Italian Breads",
        "blueberrymuffins_one": "Blueberry Muffin",
        "blueberrymuffins_other": "Blueberry Muffins",
        "cinnamonmuffins_one": "Cinnamon Muffin",
        "cinnamonmuffins_other": "Cinnamon Muffins",
        "chocolatechipmuffins_one": "Chocolate Chip Muffin",
        "chocolatechipmuffins_other": "Chocolate Chip Muffins",
        "groundbeef_one": "Ground Beef",
        "groundbeef_other": "Ground Beef",
        "filetmignon_one": "Filet Mignon",
        "filetmignon_other": "Filet Mignons",
        "stripsteak_one": "Strip Steak",
        "stripsteak_other": "Strip Steaks",
        "chickenbreasts_one": "Chicken Breast",
        "chickenbreasts_other": "Chicken Breasts",
        "chickendrumsticks_one": "Chicken Drumstick",
        "chickendrumsticks_other": "Chicken Drumsticks",
        "italiansausage_one": "Italian Sausage",
        "italiansausage_other": "Italian Sausages",
        "salmon_one": "Salmon",
        "salmon_other": "Salmon",
        "freshshrimp_one": "Fresh Shrimp",
        "freshshrimp_other": "Fresh Shrimp",
        "chips_one": "Chips",
        "chips_other": "Chips",
        "bbqchips_one": "BBQ Chips",
        "bbqchips_other": "BBQ Chips",
        "sourcreamonionchips_one": "Sour Cream & Onion Chips",
        "sourcreamonionchips_other": "Sour Cream & Onion Chips",
        "tortillachips_one": "Tortilla Chips",
        "tortillachips_other": "Tortilla Chips",
        "pretzels_one": "Pretzel",
        "pretzels_other": "Pretzels",
        "minipretzels_one": "Mini Pretzels",
        "minipretzels_other": "Mini Pretzels",
        "saltines_one": "Saltine",
        "saltines_other": "Saltines",
        "almonds_one": "Almond",
        "almonds_other": "Almonds",
        "peanuts_one": "Peanut",
        "peanuts_other": "Peanuts",
        "honeyroastedpeanuts_one": "Honey Roasted Peanut",
        "honeyroastedpeanuts_other": "Honey Roasted Peanuts",
        "mixednuts_one": "Mixed Nut",
        "mixednuts_other": "Mixed Nuts",
        "walnuts_one": "Walnut",
        "walnuts_other": "Walnuts",
        "pistachios_one": "Pistachio",
        "pistachios_other": "Pistachios",
        "cannedcorn_one": "Canned Corn",
        "cannedcorn_other": "Canned Corn",
        "cannedcreamcorn_one": "Canned Cream Corn",
        "cannedcreamcorn_other": "Canned Cream Corn",
        "cannedgreenbeans_one": "Canned Green Bean",
        "cannedgreenbeans_other": "Canned Green Bean",
        "cannedpeas_one": "Canned Pea",
        "cannedpeas_other": "Canned Peas",
        "cannedmushroomssliced_one": "Canned Mushrooms (sliced)",
        "cannedmushroomssliced_other": "Canned Mushrooms (sliced)",
        "cannedmushrooms_one": "Canned Mushrooms",
        "cannedmushrooms_other": "Canned Mushrooms",
        "cannedtomatosauce_one": "Canned Tomato Sauce",
        "cannedtomatosauce_other": "Canned Tomato Sauce",
        "cannedtomatopaste_one": "Canned Tomato Paste",
        "cannedtomatopaste_other": "Canned Tomato Paste",
        "cannedpetitedicedtomatoes_one": "Canned Petite Diced Tomato",
        "cannedpetitedicedtomatoes_other": "Canned Petite Diced Tomatoes",
        "tomatosoup_one": "Tomato Soup",
        "tomatosoup_other": "Tomato Soups",
        "chickennoodlesoup_one": "Chicken Noodle Soup",
        "chickennoodlesoup_other": "Chicken Noodle Soups",
        "creamofmushroomsoup_one": "Cream of Mushroom Soup",
        "creamofmushroomsoup_other": "Cream of Mushroom Soups",
        "creamofchickensoup_one": "Cream of Chicken Soup",
        "creamofchickensoup_other": "Cream of Chicken Soups",
        "vegetablesoup_one": "Vegetable Soup",
        "vegetablesoup_other": "Vegetable Soups",
        "bluecheesedressing_one": "Blue Cheese Salad Dressing",
        "bluecheesedressing_other": "Blue Cheese Salad Dressing",
        "ranchdressing_one": "Ranch Salad Dressing",
        "ranchdressing_other": "Ranch Salad Dressing",
        "balsalmicvinaigrettedressing_one": "Balsamic Vinaigrette Salad Dressing",
        "balsalmicvinaigrettedressing_other": "Balsamic Vinaigrette Salad Dressing",
        "worcestershiresauce_one": "Worcestershire Sauce",
        "worcestershiresauce_other": "Worcestershire Sauces",
        "soysauce_one": "Soy Sauce",
        "soysauce_other": "Soy Sauces",
        "applejuice_one": "Apple Juice",
        "applejuice_other": "Apple Juices",
        "whitegrapejuice_one": "White Grape Juice",
        "whitegrapejuice_other": "White Grape Juices",
        "grapejuice_one": "Grape Juice",
        "grapejuice_other": "Grape Juices",
        "tomatojuice_one": "Tomato Juice",
        "tomatojuice_other": "Tomato Juices",
        "cranberryjuice_one": "Cranberry Juice",
        "cranberryjuice_other": "Cranberry Juices",
        "coke_one": "Coke",
        "coke_other": "Cokes",
        "dietcoke_one": "Diet Coke",
        "dietcoke_other": "Diet Cokes",
        "pepsi_one": "Pepsi",
        "pepsi_other": "Pepsis",
        "dietpepsi_one": "Diet Pepsi",
        "dietpepsi_other": "Diet Pepsis",
        "bottledwater_one": "Bottled Water",
        "bottledwater_other": "Bottled Waters",
        "seltzerwater_one": "Seltzer Water",
        "seltzerwater_other": "Seltzer Waters",
        "sparklingwater_one": "Sparkling Water",
        "sparklingwater_other": "Sparkling Waters",
        "vanillaicecream_one": "Vanilla Ice Cream",
        "vanillaicecream_other": "Vanilla Ice Creams",
        "chocolateicecream_one": "Chocolate Ice Cream",
        "chocolateicecream_other": "Chocolate Ice Creams",
        "neopolitanicecream_one": "Neopolitan Ice Cream",
        "neopolitanicecream_other": "Neopolitan Ice Creams",
        "frozenspinach_one": "Frozen Spinach",
        "frozenspinach_other": "Frozen Spinach",
        "frozenbroccoli_one": "Frozen Broccoli",
        "frozenbroccoli_other": "Frozen Broccoli",
        "frozenpetitebroccoliflorets_one": "Frozen Broccoli Petite Floret",
        "frozenpetitebroccoliflorets_other": "Frozen Broccoli Petite Florets",
        "frozencorn_one": "Frozen Corn",
        "frozencorn_other": "Frozen Corns",
        "frozengreenbeans_one": "Frozen Green Beans",
        "frozengreenbeans_other": "Frozen Green Beans",
        "frozenpeas_one": "Frozen Peas",
        "frozenpeas_other": "Frozen Peas",
        "frozenstrawberries_one": "Frozen Strawberry",
        "frozenstrawberries_other": "Frozen Strawberries",
        "frozenblueberries_one": "Frozen Blueberry",
        "frozenblueberries_other": "Frozen Blueberries",
        "frozenwaffles_one": "Frozen Waffle",
        "frozenwaffles_other": "Frozen Waffles",
        "frozenpancakes_one": "Frozen Pancake",
        "frozenpancakes_other": "Frozen Pancakes",
        "frozentatertots_one": "Frozen Tater Tot",
        "frozentatertots_other": "Frozen Tater Tots",
        "frozenfrenchfries_one": "Frozen French Fry",
        "frozenfrenchfries_other": "Frozen French Fries",
        "pierogies_one": "Pierogies",
        "pierogies_other": "Pierogies",
        "ketchup_one": "Ketchup",
        "ketchup_other": "Ketchup",
        "bbqsauce_one": "BBQ Sauce",
        "bbqsauce_other": "BBQ Sauces",
        "blackolives_one": "Black Olive",
        "blackolives_other": "Black Olives",
        "vegetableoil_one": "Vegetable Oil",
        "vegetableoil_other": "Vegetable Oil",
        "oliveoil_one": "Olive Oil",
        "oliveoil_other": "Olive Oil",
        "semisweetchocchips_one": "Semi-Sweet Chocolate Chips",
        "semisweetchocchips_other": "Semi-Sweet Chocolate Chips",
        "darkchocchips_one": "Dark Chocolate Chips",
        "darkchocchips_other": "Dark Chocolate Chips",
        "marshmallows_one": "Marshmallow",
        "marshmallows_other": "Marshmallows",
        "cornbreadmix_one": "Cornbread Mix",
        "cornbreadmix_other": "Cornbread Mixes",
        "flour_one": "Flour",
        "flour_other": "Flour",
        "chiaseeds_one": "Chia Seed",
        "chiaseeds_other": "Chia Seeds",
        "flaxseed_one": "Flaxseed",
        "flaxseed_other": "Flaxseed",
        "bakingsoda_one": "Baking Soda",
        "bakingsoda_other": "Baking Soda",
        "bakingpowder_one": "Baking Powder",
        "bakingpowder_other": "Baking Powders",
        "wheatflour_one": "Wheat Flour",
        "wheatflour_other": "Wheat Flour",
        "sugar_one": "Sugar",
        "sugar_other": "Sugar",
        "confectionerssugar_one": "Confectioners Sugar",
        "confectionerssugar_other": "Confectioners Sugar",
        "cinnamon_one": "Cinnamon",
        "cinnamon_other": "Cinnamon",
        "salt_one": "Salt",
        "salt_other": "Salt",
        "groundpepper_one": "Ground Pepper",
        "groundpepper_other": "Ground Peppers",
        "chilipowder_one": "Chili Powder",
        "chilipowder_other": "Chili Powders",
        "basildried_one": "Basil (dried)",
        "basildried_other": "Basil (dried)",
        "oregano_one": "Oregano",
        "oregano_other": "Oregano",
        "nutmeg_one": "Nutmeg",
        "nutmeg_other": "Nutmeg",
        "groundginger_one": "Ground Ginger",
        "groundginger_other": "Ground Ginger",
        "spaghettisauce_one": "Spaghetti Sauce",
        "spaghettisauce_other": "Spaghetti Sauces",
        "pizzasauce_one": "Pizza Sauce",
        "pizzasauce_other": "Pizza Sauces",
        "spaghetti_one": "Spaghetti",
        "spaghetti_other": "Spaghetti",
        "pastashells_one": "Pasta - Shell",
        "pastashells_other": "Pasta - Shells",
        "pastabowtie_one": "Pasta - Bowtie",
        "pastabowtie_other": "Pasta - Bowties",
        "pastarotini_one": "Pasta - Rotini",
        "pastarotini_other": "Pasta - Rotinis",
        "pastatricolorrotini_one": "Pasta - Tricolor-Rotini",
        "pastatricolorrotini_other": "Pasta - Tricolor-Rotinis",
        "cannedtunawater_one": "Canned Tuna in Water",
        "cannedtunawater_other": "Canned Tuna in Water",
        "cannedtunaoil_one": "Canned Tuna in Oil",
        "cannedtunaoil_other": "Canned Tuna in Oil",
        "cannedsalmon_one": "Canned Salmon",
        "cannedsalmon_other": "Canned Salmons",
        "salmonpouch_one": "Salmon Pouch",
        "salmonpouch_other": "Salmon Pouches",
        "dishwashingliquid_one": "Dishwashing Liquid",
        "dishwashingliquid_other": "Dishwashing Liquid",
        "dishwashingdetergent_one": "Dishwashing Detergent",
        "dishwashingdetergent_other": "Dishwashing Detergent",
        "sponges_one": "Sponge",
        "sponges_other": "Sponges",
        "toiletbowlcleaner_one": "Toilet Bowl Cleaner",
        "toiletbowlcleaner_other": "Toilet Bowl Cleaners",
        "handsoap_one": "Hand Soap",
        "handsoap_other": "Hand Soaps",
        "bleachcleaner_one": "Bleach Cleaner",
        "bleachcleaner_other": "Bleach Cleaners",
        "bleach_one": "Bleach",
        "bleach_other": "Bleaches",
        "laundrydetergent_one": "Laundry Detergent",
        "laundrydetergent_other": "Laundry Detergents",
        "fabricsoftener_one": "Fabric Softener",
        "fabricsoftener_other": "Fabric Softeners",
        "colorbleach_one": "Color Bleach",
        "colorbleach_other": "Color Bleaches",
        "laundrypods_one": "Laundry Pods",
        "laundrypods_other": "Laundry Pods",
        "disinfectingwipes_one": "Disinfecting Wipe",
        "disinfectingwipes_other": "Disinfecting Wipes",
        "steelwoolpads_one": "Steel Wool Pad",
        "steelwoolpads_other": "Steel Wool Pads",
        "drainclogremover_one": "Drain Clog Remover",
        "drainclogremover_other": "Drain Clog Removers",
        "bandaids_one": "Band Aids",
        "bandaids_other": "Band Aids",
        "cottonballs_one": "Cotton Balls",
        "cottonballs_other": "Cotton Balls",
        "nailpolishremover_one": "Nail Polish Remover",
        "nailpolishremover_other": "Nail Polish Removers",
        "notebook_one": "Notebook",
        "notebook_other": "Notebooks",
        "scotchtape_one": "Scotch Tape",
        "scotchtape_other": "Scotch Tapes",
        "maskingtape_one": "Masking Tape",
        "maskingtape_other": "Masking Tapes",
        "packagingtape_one": "Packaging Tape",
        "packagingtape_other": "Packaging Tapes",
        "copypaper_one": "Copy Paper",
        "copypaper_other": "Copy Paper",
        "superglue_one": "Super Glue",
        "superglue_other": "Super Glues",
        "glue_one": "Glue",
        "glue_other": "Glues",
        "crayons_one": "Crayon",
        "crayons_other": "Crayons",
        "pencils_one": "Pencil",
        "pencils_other": "Pencils",
        "pens_one": "Pen",
        "pens_other": "Pens",
        "envelopes_one": "Envelope",
        "envelopes_other": "Envelopes",
        "paperclips_one": "Paper Clip",
        "paperclips_other": "Paper Clips",
        "rubberbands_one": "Rubber Bands",
        "rubberbands_other": "Rubber Bands",
        "scissors_one": "Scissors",
        "scissors_other": "Scissors",
        "papertowels_one": "Paper Towel",
        "papertowels_other": "Paper Towels",
        "toiletpaper_one": "Toilet Paper",
        "toiletpaper_other": "Toilet Paper",
        "tacoshells_one": "Taco Shell",
        "tacoshells_other": "Taco Shells",
        "salsajar_one": "Salsa (Jar)",
        "salsajar_other": "Salsa (Jar)",
        "tacoseasoning_one": "Taco Seasoning",
        "tacoseasoning_other": "Taco Seasoning",
        "tortillas_one": "Tortilla",
        "tortillas_other": "Tortillas",
        "raisinbran_one": "Raisin Bran",
        "raisinbran_other": "Raisin Bran",
        "maplesyrup_one": "Maple Syrup",
        "maplesyrup_other": "Maple Syrups",
        "windshieldwiperfluid_one": "Windshield Wiper Fluid",
        "windshieldwiperfluid_other": "Windshield Wiper Fluid",
        "coffee_one": "Coffee",
        "coffee_other": "Coffee",
        "coffeecreamer_one": "Coffee Creamer",
        "coffeecreamer_other": "Coffee Creamers",
        "tea_one": "Tea",
        "tea_other": "Tea",
        "clementines_one":"Clementine",
        "clementines_other":"Clementines"
        }
}